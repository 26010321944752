import { ethers } from "ethers";
import Base721 from "./Base721";
import config from "../config";
import vxabi from "../abis/vx.json";

class VXContract extends Base721 {
    constructor(signer: ethers.Signer | ethers.providers.Provider) {
        super(config.vxBearsAddress, vxabi, signer);
    }
}

export default VXContract;