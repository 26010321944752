import React, { useEffect, useState } from 'react';
import osLogo from '../images/os_logo.png'
import discordLogo from '../images/discord_logo.png'
import twitterLogo from '../images/twitter_logo.png'
import instaLogo from '../images/instagram_logo.png'
import Grid from './bearGrid';
import cart from "../images/cart.png";
import Footer from './reusable/footer';

function About() {

  const [animBG, setAnimBg] = useState(false);
  const [animTitle, setAnimTitle] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAnimBg(true);
    }, 300);
    setTimeout(() => {
      setAnimTitle(true);
    }, 390);
  }, [])

  // ffa2f8
  return (
    <div style={{zIndex:1, height: '100%'}} className={`flex flex-col justify-between bg-gradient-to-t to-transparent via-transparent from-mainbg transition-all duration-1000 bg-size-300 ${animBG ? "bg-pos-100" : "bg-pos-0"}`}>
        <div className={`p-5 mx-auto max-w-2xl h-full text-center transition-all east-out duration-1000 ${animTitle ? 'opacity-100' : 'opacity-0'}`} >
          <p style={{color: "#ffffff"}} className="mt-2 text-6xl flex justify-center font-bold tracking-tight sm:text-6xl">
            Bears Deluxe
          </p>
          <p className="mt-2 text-2xl text-white">
           6,900 algorithmically generated bears living on the Ethereum blockchain. All art drawn by hand in 24px format. Explore the collection's ecosystem through gamified mints, ownable in-game assets, custom merchandise, and cross project integrations.
          </p>

          <div className="px-4 py-3 sm:px-6 flex justify-center">
            <button
              type="button"
              className="mt-3 mr-3 flex justify-center align-items-center h-10 bg-cover bg-center rounded-full border border-transparent bg-purple-500 px-5 py-2 text-base text-white shadow-sm hover:cursor-pointer hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              style={{backgroundImage: `url(${osLogo})`, backgroundSize: '45px'}}
              onClick={() => {window.open('https://opensea.io/collection/bears-deluxe', '_blank');}}
              onTouchStart={() => {window.open('https://opensea.io/collection/bears-deluxe', '_blank');}}
            >
            </button>
            <button
              type="button"
              className="mt-3 mr-3 flex align-items-center h-10 bg-center rounded-full border border-transparent bg-purple-500 px-5 py-2 text-base text-white shadow-sm hover:bg-purple-700 hover:cursor-pointer focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              style={{backgroundImage: `url(${discordLogo})`, backgroundSize: '25px', backgroundRepeat: 'no-repeat'}}
              onClick={() => {window.open('https://discord.gg/bearsdeluxe', '_blank');}}
              onTouchStart={() => {window.open('https://discord.gg/bearsdeluxe', '_blank');}}

            >
            </button>
            <button
              type="button"
              className="mt-3 mr-3 flex align-items-center h-10 bg-center rounded-full border border-transparent bg-purple-500 px-5 py-2 text-base text-white shadow-sm hover:bg-purple-700 hover:cursor-pointer focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              style={{backgroundImage: `url(${twitterLogo})`, backgroundSize: '25px', backgroundRepeat: 'no-repeat'}}
              onClick={() => {window.open('https://twitter.com/bears_deluxe', '_blank');}}
              onTouchStart={() => {window.open('https://twitter.com/bears_deluxe', '_blank');}}

            >
            </button>
            <button
              type="button"
              className="mt-3 mr-3 flex align-items-center h-10 bg-center rounded-full border border-transparent bg-purple-500 px-5 py-2 text-base text-white shadow-sm hover:bg-purple-700 hover:cursor-pointer focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              style={{backgroundImage: `url(${instaLogo})`, backgroundSize: '25px', backgroundRepeat: 'no-repeat'}}
              onClick={() => {window.open('https://www.instagram.com/bearsdeluxe/', '_blank');}}
              onTouchStart={() => {window.open('https://www.instagram.com/bearsdeluxe/', '_blank');}}
            >
            </button>
            <button
              type="button"
              className="mt-3 mr-3 flex align-items-center h-10 bg-center rounded-full border border-transparent bg-purple-500 px-5 py-2 text-base text-white shadow-sm hover:bg-purple-700 hover:cursor-pointer focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              style={{backgroundImage: `url(${cart})`, backgroundSize: '26px', backgroundRepeat: 'no-repeat'}}
              onClick={() => {window.open('https://merch.bearsdeluxe.io', '_blank');}}
              onTouchStart={() => {window.open('https://merch.bearsdeluxe.io', '_blank');}}
            >
            </button>
          </div>
        </div>
        <div className='mb-auto h-full'>
          <Grid />
        </div>
      <Footer />
    </div>
  );
}
export default About;
