import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { WalletIcon } from '@heroicons/react/24/outline';
import {currentWallet, getWalletConfig, walletConfigs} from './wallets';
import Dropdown from '../components/reusable/dropdown';
import { WalletName } from '@web3-wallet/react';
import ethLogo from '../images/eth_logo.png';
import polyLogo from '../images/polygon_logo.png';
import { EnsPlugin } from '@web3-wallet/plugin-ens';



 interface WalletModalProps {
  setOpen: (open: boolean) => void,
  open: boolean
}

export default function WalletModal(props: WalletModalProps) {

  const cancelButtonRef = useRef(null)
  const [selectedWallet, setSelectedWallet] = useState<string>("");
  const [walletIcon, setwalletIcon] = useState<string>(walletConfigs[0].icon);
  const {useName, useChainId, useIsConnected, useAccount} = currentWallet;
  const chainId = useChainId();
  const isConnected = useIsConnected();
  const account = useAccount();
  const ensPlugin: any = currentWallet.getPluginApi(EnsPlugin.name);
  const ensData = ensPlugin.useEnsName();
  const [errorString, setErrorString] = useState<string>("");
  const walletConfig = getWalletConfig(useName());

  const [radioMessage, setRadioMessage] = useState<string>("disconnected");
  const [radioBG, setRadioBG] = useState<string>("checked:bg-teal-400");
  const [radioTextColor, setRadioTextColor] = useState<string>("text-teal-400");


  useEffect(() => {
    if (isConnected) {
      let abrv = account?.slice(0, 4) + "..." + account?.slice(-4);
      if (ensData.data !== null && ensData.data !== undefined) {
        abrv = ensData.data;
      };
      setRadioMessage("connected with " + abrv);
      setRadioBG("checked:bg-teal-400");
      setRadioTextColor("text-teal-400");
    } else {
      setRadioMessage("disconnected");
      setRadioBG("checked:bg-gray-300");
      setRadioTextColor("text-gray-300");
    };
  }, [account, isConnected, chainId, ensData.data])

  const walletsToDropdown = () => {

    return walletConfigs.map(wc => {
      return {
        text: wc.name,
        value: wc.name
      }
    })
  }

  const chainDropdown = () => {

    return [
      {
        text: "Ethereum",
        value: 1
      },
      // {
      //   text: "Polygon",
      //   value: 137
      // }
    ]

  }

  const setConnectionStatus = () => {

  }

  const selectWallet = (walletName: WalletName<string>) => {
    
    currentWallet.connectAsCurrentWallet(walletName, chainId)
    .then(res => {
        console.log("connected");

        setSelectedWallet(walletName);
        walletConfigs.forEach(wc => {
          if (wc.name === walletName) {
            setwalletIcon(wc.icon);
          };
        });

        setErrorString("");

        localStorage.setItem("walletStatus", JSON.stringify({
          walletName: walletName,
          chainId: chainId,
          status: 'connected'

        }));

      })
      .catch(res => {
        setErrorString("failed to connect wallet or chain: " + res.message);
        localStorage.setItem("walletStatus", JSON.stringify({
          walletName: walletName,
          chainId: chainId,
          status: 'disconnected'
        }));
        currentWallet.disconnect();
      });

  }

  const selectChain = (newChainId: number) => {

    currentWallet.connectAsCurrentWallet(walletConfig.name, newChainId)
    .then(res => {
        console.log("connected");

        setSelectedWallet(walletConfig.name);
        walletConfigs.forEach(wc => {
          if (wc.name === walletConfig.name) {
            setwalletIcon(wc.icon);
          };
        });

        setErrorString("");

        localStorage.setItem("walletStatus", JSON.stringify({
          walletName: walletConfig.name,
          chainId: newChainId,
          status: 'connected'

        }));

      })
      .catch(res => {
        setErrorString("failed to connect wallet or chain: " + res.message);
        localStorage.setItem("walletStatus", JSON.stringify({
          walletName: walletConfig.name,
          chainId: newChainId,
          status: 'disconnected'
        }));
        currentWallet.disconnect();
      });
  }


  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >

              <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div style={{border: "1.25px solid #d733d2"}} className='rounded-lg' >
              <div style={{border: "1.25px solid #ffa2f8"}} className='rounded-lg' >
              <div style={{border: "1.25px solid #c4f6f9"}} className='rounded-lg' >
              <div style={{border: "1.25px solid #55bac3"}} className='rounded-lg' >
                <div className="bg-gray-800 rounded-t-lg px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex flex-row sm:flex-column space-x-8">
                  <div className="sm:flex md:pl-4">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-white sm:mx-0 sm:h-10 sm:w-10">
                      <img
                      className="h-6 w-auto"
                      src={walletIcon}
                      alt="wallet icon"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-white">
                        Connect Wallet
                      </Dialog.Title>
                      <div className="mt-2">
                        <Dropdown items={walletsToDropdown()} initialSelection={isConnected ? walletConfig.name : "Please Select"} callback={selectWallet} />
                      </div>
                    </div>
                  </div>

                  <div className="sm:flex md:pl-7">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-white sm:mx-0 sm:h-10 sm:w-10">
                      <img
                      className="h-6 w-auto"
                      src={chainId === 1 ? ethLogo : chainId === 137 ? polyLogo : ethLogo}
                      alt="chain icon"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-white">
                        Select Chain
                      </Dialog.Title>
                      <div className="mt-2">
                        <Dropdown items={chainDropdown()} initialSelection={isConnected ? chainId == 1 ? "Ethereum" : chainId == 137 ? "Polygon" : "" : "Please Select ..."} callback={selectChain} />
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="bg-gray-800 pt-4 rounded-b-lg px-4 py-3 justify-between sm:flex sm:flex-row sm:px-6">
                  
                  <div>
                      <div className="md:relative md:left-6 md:top-4 sm:mb-3">
                        <input type="radio" checked={true} className={`${radioBG}`} />
                        <label className={`ml-2 text-md font-medium ${radioTextColor}`}>{radioMessage}</label>
                      </div>
                  </div>

                  <div>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-gray-300 px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => props.setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Done
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-purple-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => currentWallet.disconnect()}
                    >
                      Disconnect
                    </button>
                  </div>

                </div>


                </div>
                </div>
                </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}