const config = {
    infuraKey: "9b6bc9ea9874458c9a589490ee7335d5",
    infuraUrl: "https://mainnet.infura.io/v3/9b6bc9ea9874458c9a589490ee7335d5",
    alchemyKey: "",
    // siteUrl: "http://localhost:3000/",
    siteUrl: "http://bearsdeluxe.io/",
    honeycombsAddress: "0x66E7E1eeDF4C67b127e7B9E865cA98AA56739965",
    beekeeperProxyAddress: "0x1987649cB899B6141E31374e8597a59740eA65c5",
    bearsAddress: "0x4BB33f6E69fd62cf3abbcC6F1F43b94A5D572C2B",
    bridgeAddress: "0x2c34E1c9d11C0E0DFc8f3334e6Dd03a7c1166055",
    beesAddress:"0x1c2CD50f9Efb463bDd2ec9E36772c14A8D1658B3",
    hivesAddress: "0x5df89cC648a6bd179bB4Db68C7CBf8533e8d796e",
    honeyAddress:"0x40615B82999b8aa46803F11493BeDAB0314EB5E7", 
    queenProxyAddress: "0xCBE6EB4545E10f1eca15E940ABbdDe522a3BbA87",
    honeyFarmQueenAddress:"0x9866aF79A5B05819D01b487CAF71e232a634D62C",
    vxBearsAddress: "0xAf9F836cc3E4019AddD529c2579e44938252a1aE",
    claimVxAddress: "0x2cEa61e0Ec92009E6623532413a2961B0E2Ca5Ff",
    openSeaBearsCollection: "https:/opensea.io/bears-deluxe",
    vxMinter: "0x47132615927DC2133bFf0052838F039c188f9c33",
    beesCID: "https://ethers.bearsdeluxe.workers.dev/?token=",
    bearsCID: "https://bearsdeluxe.mypinata.cloud/ipfs/QmeragsHba1EtevJsm6sEXNJV1AewLQGaPQbbAZMtizTuF",
    hivesCID: "https://bearsdeluxe.mypinata.cloud/ipfs/QmYUNvZLcnjqPMCKXnjupSWWw459YtMWE1uYoacamhjPL2",
    VXCID: "https://bearsdeluxe.mypinata.cloud/ipfs/QmWrk6JYNaNcmWTucmeJ8iweLTjRHYEcoiUBMczCcKqSiV",
    openseaSharedAddress: "0x495f947276749Ce646f68AC8c248420045cb7b5e",
    kartAuthServer: "https://karts.bearsdeluxe.io/",
}

export default config;