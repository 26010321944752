import { ethers } from "ethers";
import Base721 from "./Base721";
import config from "../config";
import bearsabi from "../abis/bears.json";

class BearsContract extends Base721 {
    constructor(signer: ethers.Signer | ethers.providers.Provider) {
        super(config.bearsAddress, bearsabi, signer);
    }
}

export default BearsContract;