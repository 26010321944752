import { ethers } from 'ethers';
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface Transaction {
  hash: string;
  timestamp: number;
}

interface TransactionContextValue {
  pendingTransactions: Transaction[];
  addTransaction: (x: ethers.ContractTransaction, callback?: (data: any) => void) => void;
  removeTransaction: (hash: string) => void;
}

const TransactionContext = createContext<TransactionContextValue | undefined>(undefined);

export const useTransactionContext = () => {
  const context = useContext(TransactionContext);

  if (!context) {
    throw new Error('useTransactionContext must be used within a TransactionProvider');
  }

  return context;
};

interface TransactionProviderProps {
  children: ReactNode;
}

export const TransactionProvider: React.FC<TransactionProviderProps> = ({ children }) => {
  const [pendingTransactions, setPendingTransactions] = useState<Transaction[]>([]);

  const addTransaction = (tx: ethers.ContractTransaction, callback?: (data: any) => void) => {
    const hash = tx.hash;
    setPendingTransactions((prev) => [...prev, { hash, timestamp: Date.now() }]);

    tx.wait()
      .then((data) => {
        if (callback) {
          callback(data);
        }
        removeTransaction(hash);
      })
      .catch((error) => {
        console.error(`Transaction error for hash ${hash}:`, error);
        removeTransaction(hash);
      });
  };

  const removeTransaction = (hash: string) => {
    setPendingTransactions((prev) => prev.filter((tx) => tx.hash !== hash));
  };

  return (
    <TransactionContext.Provider value={{ pendingTransactions, addTransaction, removeTransaction }}>
      {children}
    </TransactionContext.Provider>
  );
};
