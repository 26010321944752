import React, { useEffect, useState } from 'react';
//ts-ignore
import contractOptions from '../../helpers/contractOptions';
import SpriteAnimator from './spritesheetAnimator';
import Tooltip from './Tooltip';
import ModelDisplay from '../vxDisplay';

interface NFTDisplayProps {
  contract: contractOptions;
  showTooltips?: boolean;
  disable?: boolean;
  nft: {
    id: string;
    name: string;
    image: string;
    metaverse_url?: string;
    animation_url: string;
    metadata: Array<{
      trait_type: string;
      value: string | number;
    }>;
  };
}

const animOptions: any = {
  'walk down': [0, 4, 6],
  'walk right': [8, 4, 6],
  'walk up': [16, 4, 6],
  'walk left': [24, 4, 6],
  'idle down': [32, 1, 2],
  'idle right': [40, 1, 2],
  'idle up': [48, 1, 2],
  'idle left': [56, 1, 2],
  'run down': [64, 8, 16],
  'run right': [72, 8, 16],
  'run up': [80, 8, 16],
  'run left': [88, 8, 16],
  'bonk left': [96, 2, 4],
  'bonk right': [98, 2, 4],
  'bonk down': [100, 2, 4],
  'bonk up': [102, 2, 4],
  'sleep left': [104, 2, 2],
  'sleep right': [106, 2, 2],
  'sit right': [108, 1, 2],
  'sit down': [109, 1, 2],
  'sit up': [110, 1, 2],
  'sit left': [111, 1, 2],
}

const options = [
  'walk down',
  'walk right',
  'walk left',
  'walk up',
  'idle down',
  'idle right',
  'idle up',
  'idle left',
  'run down',
  'run right',
  'run up',
  'run left',
  'bonk left',
  'bonk right',
  'bonk down',
  'bonk up',
  'sleep left',
  'sleep right',
  'sit right',
  'sit down',
  'sit up',
  'sit left',
]



const NFTDisplay: React.FC<NFTDisplayProps> = ({ contract, nft, disable = false, showTooltips = false }) => {
  const [selectedOption, setSelectedOption] = useState('walk down');
  const [loading, setLoading] = useState<any>(true);

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    setLoading(true);
  }, [nft.image])

  return (
    <div className="flex flex-col items-center w-full px-4 py-8">
      <img
        src={nft.image}
        alt={`${nft.name}}`}
        onLoad={() => { setLoading(false) }}
        className="w-80 h-auto scale-105 w-full rounded object-cover mb-5 ml-2 mr-2"
        style={{ display: loading ? 'none' : 'block' }}
      />
      {loading && (
        <div className="inset-0 w-80 h-72 bg-black bg-opacity-50 flex items-center justify-center mb-5 rounded-lg">
          <span className="text-white">Loading...</span>
        </div>
      )}

      <div className="w-full p-4 bg-black bg-opacity-60 text-white shadow-md rounded-md w-3/4 h-3/4 object-cover mx-auto mb-3">
        <h2 className="text-lg font-semibold mb-3">{nft.name}</h2>
        <h3 className="text-lg font-semibold mb-3">Attributes</h3>
        <ul className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          {nft.metadata.map((attribute, index) => (
            <li key={index} className="flex justify-between p-3 border border-white rounded">
              <span className="text-lg max-w-xs whitespace-nowrap overflow-hidden overflow-ellipsis">{attribute.trait_type}</span> {/* Update the trait name with the new classes */}
              <span className="text-lg" >{attribute.value}</span>
            </li>
          ))}
        </ul>
      </div>
      {
        contract == 'bears' &&
        <div className="w-full p-4 bg-black bg-opacity-60 text-white shadow-md rounded-md w-3/4 h-3/4 object-cover mx-auto mb-3">
          <div className='flex justify-center mb-3 mt-3'>
            {
              showTooltips &&
              <Tooltip style={{ marginRight: "5px", position: 'relative', top: '4px' }} message="select animations from the dropdown. you can download the spritesheet for this bear in the section below." />
            }
            <label className="block text-xl text-white font-bold mb-2" htmlFor="dropdown">
              Animation
            </label>
            <div>
              <select
                id="dropdown"
                // defaultValue={selectedOption}
                value={selectedOption}
                onChange={handleOptionChange}
                className="block appearance-none w-full bg-transparent ml-3 border-b-2 border-blue-200 text-white px-4 py-2 pr-8 rounded shadow relative bottom-2 leading-tight focus:outline-none focus:shadow-outline"
              >
                {options.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <SpriteAnimator
            imageUrl={`https://vx-stills.s3.us-east-2.amazonaws.com/sheets/${nft.id}.png`}
            scale={6}
            columns={8}
            frameRate={1000 / animOptions[selectedOption][2]}
            startingFrame={animOptions[selectedOption][0]}
            frameCount={animOptions[selectedOption][1]}
          />
        </div>
      }
      {
        (contract == 'bears' && !disable) &&
        <div className="w-full p-4 bg-black bg-opacity-60 text-white shadow-md rounded-md w-3/4 h-3/4 object-cover mx-auto">
          <div className="flex flex-col xl:flex-row xl:justify-center items-center space-y-2 xl:space-y-0 xl:space-x-2">
            <button onClick={() => { window.open("https://vx-stills.s3.us-east-2.amazonaws.com/big/" + String(nft.id) + ".png") }} className="py-2 px-4 bg-black bg-opacity-50 text-white border border-white whitespace-nowrap rounded-md">
              Download PFP
            </button>
            <button onClick={() => { window.open("https://vx-stills.s3.us-east-2.amazonaws.com/transparentBears/" + String(nft.id) + ".png") }} className="py-2 px-4 bg-black bg-opacity-50 text-white border border-white whitespace-nowrap rounded-md">
              Download PFP (No Background)
            </button>
            <button onClick={() => { window.open("https://vx-stills.s3.us-east-2.amazonaws.com/sheets/" + String(nft.id) + ".png") }} className="py-2 px-4 bg-black bg-opacity-50 text-white border border-white whitespace-nowrap rounded-md">
              Download Spritesheet
            </button>
            <button onClick={() => { window.open("https://ordinals.com/content/3a18cf0a27b88424bf610d6304cce3191870c3268d6c869baeb7a4a9b0afbc9di0?id=" + String(nft.id)) }} className="py-2 px-4 bg-black bg-opacity-50 text-white border border-white whitespace-nowrap rounded-md">
              View Ordinal
            </button>
          </div>
        </div>
      }
      {
        contract == 'vx' &&
          nft.metaverse_url ? <ModelDisplay modelUrl={`${nft.metaverse_url}`} /> : <></>
      }
      {
        (contract == 'vx' && !disable) &&
        <div className="w-full p-4 bg-black bg-opacity-60 text-white shadow-md rounded-md w-100 h-3/4 object-cover mx-auto ,b-3">
          <div className="flex flex-row justify-center items-center space-y-4 space-y-0 space-x-4">
            <button onClick={() => { window.open(nft?.metaverse_url) }} className="py-2 px-4 bg-black bg-opacity-50 text-white border border-white whitespace-nowrap rounded-md">
              Download GLTF
            </button>
            <button onClick={() => { window.open("https://vx-stills.s3.us-east-2.amazonaws.com/fbx_zips/" + String(nft.id) + ".zip") }} className="py-2 px-4 bg-black bg-opacity-50 text-white border border-white whitespace-nowrap rounded-md">
              Download FBX
            </button>
          </div>
        </div>
      }
      {
        (contract == 'hives' && !disable) &&
        <div className="w-full p-4 bg-black bg-opacity-60 text-white shadow-md rounded-md w-3/4 h-3/4 object-cover mx-auto">
          <div className="flex flex-col xl:flex-row xl:justify-center items-center space-y-4 xl:space-y-0 xl:space-x-4">
            <button onClick={() => { window.open(nft.image) }} className="py-2 px-4 bg-black bg-opacity-50 text-white border border-white whitespace-nowrap rounded-md">
              Download GIF
            </button>
          </div>
        </div>
      }
      {
        (contract == 'bees' && !disable) &&
        <div className="w-full p-4 bg-black bg-opacity-60 text-white shadow-md rounded-md w-3/4 h-3/4 object-cover mx-auto">
          <div className="flex flex-col xl:flex-row xl:justify-center items-center space-y-4 xl:space-y-0 xl:space-x-4">
            <button onClick={() => { window.open(nft.image) }} className="py-2 px-4 bg-black bg-opacity-50 text-white border border-white whitespace-nowrap rounded-md">
              Download PFP
            </button>
          </div>
        </div>
      }
    </div>
  );
};

export default NFTDisplay;
