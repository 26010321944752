import { ethers } from "ethers";
import Base721 from "./Base721";
import config from "../config";
import hivesabi from "../abis/hives.json";

class HivesContract extends Base721 {
    constructor(signer: ethers.Signer | ethers.providers.Provider) {
        super(config.hivesAddress, hivesabi, signer);
    }
}

export default HivesContract;