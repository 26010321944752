import { rpcMap } from './chains';
import { BraveWallet } from '@web3-wallet/brave-wallet';
import { CoinbaseWallet } from '@web3-wallet/coinbase-wallet';
import { WalletConnect } from '@web3-wallet/walletconnect';
import { MetaMask } from '@web3-wallet/metamask';
import { TrustWallet } from '@web3-wallet/trust-wallet';
import { BalancePlugin } from '@web3-wallet/plugin-balance';
import { EnsPlugin } from '@web3-wallet/plugin-ens';
import type { WalletName } from '@web3-wallet/react';
import { createCurrentWallet } from '@web3-wallet/react';
import { WalletConfig } from './customTypes';

export const plugins = [EnsPlugin.create(), BalancePlugin.create()];

export const walletConfigs: WalletConfig[] = [
  {
    name: MetaMask.walletName,
    icon: MetaMask.walletIcon,
    connector: new MetaMask(),
  },
  {
    name: CoinbaseWallet.walletName,
    icon: CoinbaseWallet.walletIcon,
    connector: new CoinbaseWallet({
      providerOptions: {
        appName: 'Bears Deluxe',
        linkAPIUrl:'https://eth-mainnet.alchemyapi.io/v2/9b6bc9ea9874458c9a589490ee7335d5'
      },
    }),
  },
  {
    name: WalletConnect.walletName,
    icon: WalletConnect.walletIcon,
    connector: new WalletConnect({
      providerOptions: {
        rpc: rpcMap,
      },
    }),
  },
  {
    name: TrustWallet.walletName,
    icon: TrustWallet.walletIcon,
    connector: new TrustWallet(),
  },
  {
    name: BraveWallet.walletName,
    icon: BraveWallet.walletIcon,
    connector: new BraveWallet(),
  }
];

export const getWalletConfig = (name: WalletName): WalletConfig => {
  return walletConfigs.find((v) => v.name === name) as WalletConfig;
};

export const currentWallet = createCurrentWallet(
  walletConfigs.map((v) => v.connector),
  {
    plugins,
  },
);