import React, {Fragment, useEffect, useState} from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import logo1 from '../images/logo1.png'
import logo3 from '../images/logo3.png'
import ethLogo from '../images/eth_logo.png';
import polyLogo from '../images/polygon_logo.png';
import { NavOptions } from '../helpers/customTypes';
import { EnsPlugin } from '@web3-wallet/plugin-ens';
import {currentWallet} from '../helpers/wallets';
import { useLocation, useNavigate } from "react-router-dom";
import TransactionSpinner from './TransactionSpinner';


function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
}

interface NavProps {
    setOpen: Function,
    open: boolean,
    setActiveUrl: Function,
    activeUrl: NavOptions,
    handleTxModalClick: () => void
}

function Nav(props: NavProps) {

    const {useAccount, useChainId, useIsConnected} = currentWallet;
    const ensPlugin: any = currentWallet.getPluginApi(EnsPlugin.name);
    const ensData = ensPlugin.useEnsName();
    const account = useAccount();
    const isConnected = useIsConnected();
    const chainId = useChainId();
    const navigate = useNavigate();
    const location = useLocation();
    const [navigation, setNavigation] = useState<any[]>([
        { name: 'Home', href: '/', current: false, external: false},
        { name: 'History', href: '/history', current: false, external: false },
        { name: 'Games', href: '/games', current: false, external: false },
        { name: 'Assets', href: '/assets', current: false, external: false },
        { name: 'Docs', href: 'https://docs.bearsdeluxe.io/', current: false, external: true },
    ]);

    useEffect(() => {
        const updateNavigation = () => {
          let anyCurrent = false;
      
          const updatedNavigation = navigation.map((navItem) => {
            if (location.pathname.includes(navItem.href) && navItem.href !== '/') {
              anyCurrent = true;
              return { ...navItem, current: true };
            } else {
              return { ...navItem, current: false };
            }
          });
      
          if (!anyCurrent && location.pathname === '/') {
            const index = updatedNavigation.findIndex((navItem) => navItem.href === '/');
            updatedNavigation[index].current = true;
          };

          setNavigation(updatedNavigation);
        };
      
        updateNavigation();
      }, [location]);
    

    const onNavSelect = (name: string, route: string) => {
        let copy = Array.from(navigation);

        let updated = copy.map(c => {
            return {
                name: c.name,
                href: c.href,
                current: name === c.name ? true : false
            }
        });

        if (name == "Docs") {
            window.open('https://docs.bearsdeluxe.io/', '_blank');
        } else {
            setNavigation(updated);
            navigate(route);
        }


    }

    return (
    <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
        <>
            <div className='shadow-lg'  style={{borderBottom: "1.25px solid #d733d2"}}>
            <div style={{borderBottom: "1.25px solid #ffa2f8"}}>
            <div style={{borderBottom: "1.25px solid #c4f6f9"}}>
            <div style={{borderBottom: "1.25px solid #55bac3"}}>
            {/* <div style={{borderBottom: "1.25px solid #55bac3"}}> */}

            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open menu</span>
                    {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                    <img
                    className="block h-12 w-auto lg:hidden"
                    src={logo1}
                    alt="Bears Deluxe"
                    />
                    <img
                    className="hidden h-12 w-auto lg:block"
                    src={logo1}
                    alt="Bears Deluxe"
                    />
                </div>
                <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                    {navigation.map((item) => (
                        <span
                        key={item.name}
                        onClick={() => {onNavSelect(item.name, item.href)}}
                        className={classNames(
                            item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'px-3 py-2 rounded-md text-md font-medium text-xl'
                        )}
                        style={{cursor: "pointer"}}
                        aria-current={item.current ? 'page' : undefined}
                        >
                        {item.name}
                        </span>
                    ))}
                    </div>
                </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {
                    chainId == 1 &&
                    <img 
                    className="h-7 mr-2"
                    src={ethLogo}
                    alt=""
                    />
                }
                {
                    chainId == 137 &&
                    <img 
                    className="h-6 mr-2"
                    src={polyLogo}
                    alt=""
                    />
                }
                {
                    isConnected &&
                    <div className="text-gray-300 text-xl hidden sm:block">
                        {
                            (ensData.data !== null && ensData.data !== undefined) &&
                            ensData.data
                        }
                        {
                            (ensData.data === null || ensData.data === undefined) &&
                            account?.slice(0, 4) + "..." + account?.slice(-4)
                        }
                    </div>
                }
                {
                    !isConnected &&
                    <div className="text-gray-400 text-lg">
                        disconnected
                    </div>
                }

                {/* Wallet Connect Dropdown */}
                <Menu as="div" className="relative ml-3">
                    <div>
                    <Menu.Button onClick={() => {props.setOpen(true)}} className="flex  bg-gray-800 text-sm focus:outline-none">
                        <span className="sr-only">Connect Wallet</span>
                        <img
                        className="h-10 w-10 "
                        src={logo3}
                        alt=""
                        />
                    </Menu.Button>
                    </div>
                </Menu>
                <TransactionSpinner handleSpinnerClick={props.handleTxModalClick} />
                </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            {/* </div> */}

            <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
                {navigation.map((item) => (
                <Disclosure.Button
                    key={item.name}
                    as="span"
                    // href={item.href}
                    onClick={() => {onNavSelect(item.name, item.href)}}
                    className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium text-lg'
                    )}
                    style={{cursor: "pointer"}}
                    aria-current={item.current ? 'page' : undefined}
                >
                    {item.name}
                </Disclosure.Button>
                ))}
            </div>
            </Disclosure.Panel>
        </>
        )}
    </Disclosure>
    )
}

export default Nav;