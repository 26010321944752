import React, { useRef, useState } from 'react';
import randomMosiacImg from '../images/mosiac.png';
import orderedMosiac from '../images/mosiac2.png';
import Modal from 'react-modal';
import PanZoom from 'react-easy-panzoom';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "../App.css";


Modal.setAppElement('#root');

interface StaticGridProps {
  isRandom: boolean;
  width: number;
  height: number;
  className?: string;
  animdisplay: boolean;
}

const StaticGrid: React.FC<StaticGridProps> = ({ isRandom, className, width, height, animdisplay }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const panZoomRef = useRef(null);

  const handleClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const imgSrc = isRandom ? randomMosiacImg : orderedMosiac;

  return (
    <div       
      className={`pan-zoom-container rounded-lg bg-gray-600 transition-all duration-1000 ease-out ${animdisplay ? '' : 'scale-0'}`}
      style={{ width, height, overflow: 'hidden', position: 'relative' }}
      >
      <img src={imgSrc} alt="mosaic" onClick={handleClick}         
        style={{
          width: 'auto',
          height: 'auto',
          minWidth: width,
          minHeight: height,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%) scale(5)',
          cursor: 'pointer'
        }} />
      <Modal overlayClassName="modal-container-index"
        className="modal-content" isOpen={modalOpen} onRequestClose={handleCloseModal}>
        <TransformWrapper initialScale={10} maxScale={45}>
          <TransformComponent>
            <img src={imgSrc} alt="mosaic" />
          </TransformComponent>
        </TransformWrapper>
        <button className="mt-5 mr-5 absolute top-0 right-0 bg-black bg-opacity-50 text-white border border-white rounded px-2 py-1 hover:bg-opacity-75 focus:outline-none" onClick={handleCloseModal}>
          Close
        </button>
      </Modal>
    </div>
  );
};

export default StaticGrid;