import { Spinner } from 'flowbite-react';
import React, { useRef, useEffect, useState } from 'react';
import config from '../config'


interface BearGridTooltipProps {
  visible: boolean;
  bearId: number;
  tooltipRef: any;
  coordinates: {
    x: number;
    y: number;
  };
}

const BearGridTooltip: React.FC<BearGridTooltipProps> = ({visible, tooltipRef, bearId, coordinates}) => {

    const [isLoading, setIsloading] = useState(false);
    const [bId, setBId] = useState(bearId);
    const [hideMain, setHideMain] = useState(false);

    useEffect(() => {
        if (bId !== bearId) {
            setBId(bearId);
            setIsloading(true);
        };
    }, [bearId])

    return (
        <div
        ref={tooltipRef}
        className={`p-2 shadow-xl rounded bg-gray-800 transition-opacity duration-700 ${!visible ? "pointer-events-none " : ""} ${visible ? "opacity-100" : "opacity-0"}`}
        style={{
            position: 'fixed',
            top: coordinates.y - 92,
            left: coordinates.x,
            cursor: "pointer",
            height: "255px",
            width: "175px",
            border: "2px solid #c4f6f9"
        }}
        >
            <div className="font-bold text-white text-center text-lg mb-2">Bears Deluxe #{bearId}</div>
            <div className='h-full w-full'>
                <div className={` h-full w-full flex flex-row justify-center align-items-center ${!isLoading ? 'hidden':''}`}>
                    <Spinner
                        aria-label="Extra large spinner example h-full w-full"
                        size="xl"
                    /> 
                </div>
                <img
                    className={`rounded-full mr-2 ${isLoading ? "hidden" : ""}`}
                    style={{imageRendering: 'pixelated', border: "2px solid #c4f6f9"}}
                    src={`https://vx-stills.s3.us-east-2.amazonaws.com/big/${bearId}.png`}
                    alt="avatar"
                    onLoad={() => {setIsloading(false)}}
                />
                <div className="flex flex-col items-center">
                    <p className="text-gray-500 dark:text-gray-400"><a target='_blank' href={`https://opensea.io/assets/ethereum/0x4bb33f6e69fd62cf3abbcc6f1f43b94a5d572c2b/${bearId}`} className="inline-flex items-center font-medium text-blue-600 dark:text-blue-500 hover:underline">
                        Opensea
                        <svg aria-hidden="true" className="w-5 h-5 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </a></p>
                    <p className="text-gray-500 dark:text-gray-400"><a target="_blank" href={`${config.siteUrl}bears/${bearId}`} className="inline-flex items-center font-medium text-blue-600 dark:text-blue-500 hover:underline">
                        Detailed Viewer
                        <svg aria-hidden="true" className="w-5 h-5 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </a></p>
                </div>
            </div>
        </div>
    );
};

export default BearGridTooltip;


