import React, { useEffect, useState } from 'react';
import Footer from './reusable/footer';


const Contact: React.FC = () => {
  const [animBG, setAnimBg] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAnimBg(true);
    }, 300);

  }, [])

  return (
    <div className={`min-h-screen flex flex-col bg-gradient-to-t to-transparent via-transparent from-mainbg transition-all duration-1000 bg-size-300 ${animBG ? "bg-pos-100" : "bg-pos-0"}`}>
      <div className="flex-grow">
        <div className="bg-black bg-opacity-80 text-white p-8 rounded-xl border-2 border-white mx-auto my-16 w-full max-w-4xl">
          <h1 className="text-4xl font-bold mb-8">Contact</h1>
          <p className="text-xl mb-4">
            Got questions, ideas, or just wanna chat? Hit us up at:
          </p>
          <p className="text-2xl font-semibold">
            <a
              className="text-white underline"
              href="mailto:contact.bearsdeluxe@gmail.com"
            >
              contact.bearsdeluxe@gmail.com
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;