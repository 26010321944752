import { ethers } from "ethers";
import Base721 from "./Base721";
import config from "../config";
import beesabi from "../abis/bees.json";

class BeesContract extends Base721 {
    constructor(signer: ethers.Signer | ethers.providers.Provider) {
        super(config.beesAddress, beesabi, signer);
    }
}

export default BeesContract;