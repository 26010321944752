import ethers, { ContractInterface, Signer } from 'ethers';
import BaseContract from './BaseContract';

export default abstract class Base721 extends BaseContract {
    constructor(contractAddress: string, abi: ContractInterface, signer: Signer | ethers.providers.Provider) {
        super(contractAddress, abi, signer);
    }

    async ownerOf(tokenId: number): Promise<string> {
        return await this.callFunction('ownerOf', [tokenId]);
    }

    async balanceOf(owner: string): Promise<number> {
        return await this.callFunction('balanceOf', [owner]);
    }

    async tokenOfOwnerByIndex(owner: string, index: number): Promise<number> {
        return await this.callFunction('tokenOfOwnerByIndex', [owner, index]);
    }

    async tokensOfOwner(owner: string): Promise<ethers.BigNumber[]> {
        return await this.callFunction('tokensOfOwner', [owner]);
    }

    async tokenByIndex(index: number): Promise<number> {
        return await this.callFunction('tokenByIndex', [index]);
    }

    async transferFrom(from: string, to: string, tokenId: number, options: ethers.PayableOverrides = {}): Promise<any> {
        return await this.callFunction('transferFrom', [from, to, tokenId], options);
    }

    async safeTransferFrom(from: string, to: string, tokenId: number, data?: ethers.utils.BytesLike, options: ethers.PayableOverrides = {}): Promise<any> {
        const args = data ? [from, to, tokenId, data] : [from, to, tokenId];
        return await this.callFunction('safeTransferFrom', args, options);
    }

    async approve(to: string, tokenId: number, options: ethers.PayableOverrides = {}): Promise<any> {
        return await this.callFunction('approve', [to, tokenId], options);
    }

    async getApproved(tokenId: number): Promise<string> {
        return await this.callFunction('getApproved', [tokenId]);
    }
}
