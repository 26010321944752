import React, { useRef, useEffect, useState, TouchEventHandler } from 'react';
import orderedMosiac from '../images/new-mosiac-ordered.png';
import randomMosiacImg from '../images/new-mosiac.png';
import { id } from 'ethers/lib/utils';
import { listeners } from 'process';
import useWindowSize from '../customHooks/useWindowSize';
import BearGridTooltip from './bearGridTooltip';
import randomMosiac from '../helpers/randomMosiacNew';
import StaticGrid from './staticGrid';
import Tooltip from './reusable/Tooltip';

const Grid: React.FC = () => {

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const overlayRef = useRef<HTMLCanvasElement>(null);

  const [isRandom, setIsRandom] = useState<boolean>(true);
  const [scale, setScale] = useState(.5);
  const [img, setImg] = useState<CanvasImageSource>();
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [start, setStart] = useState({ x: 0, y: 0 });
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectedBear, setSelectedBear] = useState(1);
  const [tooltipCoords, setTooltipCoords] = useState({ x: 0, y: 0 });
  const tooltipRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isDragNMove, setIsDragNMove] = useState(false);
  const [hoveredBear, setHoveredBear] = useState<number | null>(null);
  const [lastHover, setLastHover] = useState<any[]>([]);


  const [animDisplayOne, setAnimDisplayOne] = useState(false);
  const [animDisplayTwo, setAnimDisplayTwo] = useState(false);

  const size = useWindowSize();

  useEffect(() => {
    setTimeout(() => {
      setAnimDisplayOne(true);
    }, 200)

    setTimeout(() => {
      setAnimDisplayTwo(true)
    }, 200)
  }, [])

  useEffect(() => {
    const newImg = new Image();
    if (!isRandom) {
      newImg.src = orderedMosiac;
    } else {
      newImg.src = randomMosiacImg;
    }
    newImg.onload = () => setImg(newImg);
    setOffset({
      x: -1 * (Math.floor(Math.random() * (300 - 100 + 1)) + 100),
      y: -1 * (Math.floor(Math.random() * (4000 - 1000 + 1)) + 1000)
    })
  }, [isRandom])


  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    canvas.addEventListener('touchstart', handleTouchStart);
    canvas.addEventListener('touchmove', handleTouchMove);
    canvas.addEventListener('touchend', handleTouchEnd);

    return () => {
      canvas.removeEventListener('touchstart', handleTouchStart);
      canvas.removeEventListener('touchmove', handleTouchMove);
      canvas.removeEventListener('touchend', handleTouchEnd);
    };
  }, [canvasRef.current]);

  useEffect(() => {

    const wrap = wrapperRef.current;
    if (!wrap) return;

    wrap.addEventListener("wheel", (event) => { event.preventDefault() }, { passive: false })

    return () => {
      wrap.removeEventListener("wheel", (event) => { event.preventDefault() })
    }

  }, [wrapperRef.current])


  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return;
    }

    if (img === undefined) {
      return;
    }

    if (scale < .65) {
      ctx.imageSmoothingEnabled = true;
    } else {
      ctx.imageSmoothingEnabled = false;
    }
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.save();
    ctx.translate(offset.x, offset.y);
    ctx.scale(scale, scale);
    ctx.drawImage(img, 0, 0);
    ctx.restore();
    // ctx.strokeStyle = 'red';
    // ctx.lineWidth = 1 / scale;

    let bearId = 1;
    const eventListeners: any[] = [];
    const touchListeners: any[] = [];
    const hoverListeners: any[] = [];



    for (let j = 0; j < 69; j++) {
      for (let i = 0; i < 99; i++) {
        // ctx.strokeRect(i * 175 * scale + offset.x, j * 175 * scale + offset.y, 175 * scale, 175 * scale);
        const x = i * 175 * scale + offset.x;
        const y = j * 175 * scale + offset.y;
        const width = 175 * scale;
        const height = 175 * scale;
        let thisBear = isRandom ? randomMosiac[bearId - 1] : bearId;


        let listener = (event: MouseEvent) => {
          let canvasRect = canvas.getBoundingClientRect();
          let x = event.clientX - canvasRect.left;
          let y = event.clientY - canvasRect.top;
          if (x >= i * 175 * scale + offset.x && x <= i * 175 * scale + offset.x + width && y >= j * 175 * scale + offset.y && y <= j * 175 * scale + offset.y + height) {
            handleGridClick(thisBear);
          };
        }

        let touchListener = (event: TouchEvent) => {
          event.preventDefault();
          let canvasRect = canvas.getBoundingClientRect();
          let x = event.touches[0].clientX - canvasRect.left;
          let y = event.touches[0].clientY - canvasRect.top;
          if (x >= i * 175 * scale + offset.x && x <= i * 175 * scale + offset.x + width && y >= j * 175 * scale + offset.y && y <= j * 175 * scale + offset.y + height) {

            handleGridClick(thisBear);
          };
        }

        let hoverListener = (event: MouseEvent) => {
          const canvasRect = canvas.getBoundingClientRect();
          const mouseX = event.clientX - canvasRect.left;
          const mouseY = event.clientY - canvasRect.top;
          if (
            mouseX >= x &&
            mouseX <= x + width &&
            mouseY >= y &&
            mouseY <= y + height
          ) {
            setHoveredBear(thisBear);
          }
        };



        canvas.addEventListener('mouseup', listener);
        canvas.addEventListener('touchend', touchListener);
        // canvas.addEventListener("mousemove", hoverListener);

        eventListeners.push(listener);
        touchListeners.push(touchListener);
        // hoverListeners.push(hoverListener);
        bearId++;
      }
    }


    return () => {
      eventListeners.forEach((l) => {
        canvas.removeEventListener('mouseup', l);
      })
      touchListeners.forEach((l) => {
        canvas.removeEventListener('touchend', l);
      })
      // hoverListeners.forEach((l) => {
      //   canvas.removeEventListener('mousemove', l);
      // })
    }
  }, [scale, offset, img, size]);

  const handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (tooltipRef.current) {
      const { left, top, right, bottom } = tooltipRef.current.getBoundingClientRect();
      if (
        event.clientX < left ||
        event.clientX > right ||
        event.clientY < top ||
        event.clientY > bottom
      ) {
        setTooltipOpen(false);
      }
    }

    if (!dragging) {
      return;
    }

    setIsDragNMove(true);

    // @ts-ignore
    const maxOffsetX = (Number(img?.width || 0)) * scale - size[0] / 1.15;
    // @ts-ignore
    const maxOffsetY = (Number(img?.height || 0)) * scale - size[0] / 3;

    const newOffsetX = Math.min(Math.max(offset.x + (event.clientX - start.x) / scale, -maxOffsetX), 0);
    const newOffsetY = Math.min(Math.max(offset.y + (event.clientY - start.y) / scale, -maxOffsetY), 0);

    setOffset({ x: newOffsetX, y: newOffsetY });
    setStart({ x: event.clientX, y: event.clientY });
  };



  const handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    setDragging(true);
    setStart({ x: event.clientX, y: event.clientY });
  };

  const handleMouseUp = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    setDragging(false);
    if (isDragNMove === true) {
      setIsDragNMove(false);
    } else {
      setTooltipOpen(true);
    }
    setTooltipCoords({ x: event.clientX, y: event.clientY });
  };

  const handleWheel = (event: React.WheelEvent<HTMLCanvasElement>) => {
    const delta = event.deltaY < 0 ? 1.1 : 1 / 1.1;
    setScale(scale * delta);
    setOffset({
      x: offset.x - (event.clientX - offset.x) * (delta - 1),
      y: offset.y - (event.clientY - offset.y) * (delta - 1),
    });
    setTooltipOpen(false);
  };

  const handleGridClick = (bearId: number) => {
    console.log(bearId);
    setSelectedBear(bearId);
  };

  const handleTouchStart = (event: TouchEvent) => {
    setDragging(true);
    setStart({ x: event.touches[0].clientX, y: event.touches[0].clientY });
  };

  const handleTouchMove = (event: TouchEvent) => {
    if (!tooltipRef.current) return;
    const { left, top, right, bottom } = tooltipRef.current.getBoundingClientRect();
    if (
      event.touches[0].clientX < left ||
      event.touches[0].clientX > right ||
      event.touches[0].clientY < top ||
      event.touches[0].clientY > bottom
    ) {
      setTooltipOpen(false);
    }
    if (!dragging) {
      return;
    }
    setIsDragNMove(true);
    setOffset({
      x: offset.x + (event.touches[0].clientX / scale - start.x) / scale,
      y: offset.y + (event.touches[0].clientY / scale - start.y) / scale,
    });
    setStart({ x: event.touches[0].clientX / scale, y: event.touches[0].clientY / scale });
  };

  const handleTouchEnd = (event: TouchEvent) => {
    setDragging(false);
    if (isDragNMove) {
      setIsDragNMove(false);
    } else {
      setTooltipOpen(true);
    }
    setTooltipCoords({ x: offset.x + (event.touches[0].clientX / scale - start.x) / scale, y: offset.y + (event.touches[0].clientY / scale - start.y) / scale });
  };


  return (
    <div style={{ zIndex: 1 }} className={`flex justify-center transition-all duration-700 ease-out ${animDisplayOne ? '' : 'scale-0'}`}>

      <div>
        <div className="flex flex-row justify-between mb-3">
          <div className="flex flex-row ml-3">
            <p className="relative top-3 text-white">
              Select Bear Arrangement
            </p>
            <button
              type="button"
              className="mt-3 mr-3 flex align-items-center h-6 bg-center rounded border border-transparent bg-purple-500 px-3 text-base text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => { setIsRandom(!isRandom); }}
              onTouchStart={() => { setIsRandom(!isRandom); }}
            >
              {isRandom ? "RANDOM" : "ORDERED"}
            </button>
          </div>
          <span className='text-white relative top-3 mr-2'> {size[0] < 1024 && <Tooltip message='Click or touch to explore bears. View on larger screens for enhanced experience' style={{}} />} Click and drag to explore</span>
        </div>
        <div ref={wrapperRef} style={{ border: "1.25px solid #55bac3" }} className={`rounded-lg  transition-all duration-1000 ease-out ${animDisplayOne ? '' : 'scale-0'}`} >
          <div style={{ border: "1.25px solid #c4f6f9" }} className={`rounded-lg  transition-all duration-1000 ease-out ${animDisplayOne ? '' : 'scale-0'}`} >
            <div style={{ border: "1.25px solid #ffa2f8" }} className={`rounded-lg  transition-all duration-1000 ease-out ${animDisplayOne ? '' : 'scale-0'}`} >
              <div style={{ border: "1.25px solid #d733d2" }} className={`rounded-lg  transition-all duration-1000 ease-out ${animDisplayOne ? '' : 'scale-0'}`} >
                <div style={{ position: 'relative' }}>
                  {
                    size[0] > 1024 &&
                    <canvas
                      ref={canvasRef}
                      className={`rounded-lg bg-gray-600 transition-all duration-1000 ease-out ${animDisplayOne ? '' : 'scale-0'}`}
                      width={size[0] / 1.15}
                      height={size[0] / 3}
                      onMouseDown={handleMouseDown}
                      onMouseMove={handleMouseMove}
                      onMouseUp={handleMouseUp}
                      onWheel={handleWheel}
                      style={{
                        cursor: dragging ? 'grabbing' : 'pointer',
                        zIndex: 1,
                      }}

                    />
                  }
                  {
                    size[0] <= 1024 &&
                    <StaticGrid animdisplay={animDisplayOne} width={size[0] / 1.15} height={size[0] / 3} isRandom={isRandom} />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

      <BearGridTooltip tooltipRef={tooltipRef} bearId={selectedBear} coordinates={tooltipCoords} visible={tooltipOpen} />
    </div>
  );
};

export default Grid
