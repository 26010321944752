import React, { useState, useEffect } from 'react';
import '../../App.css';
import NFTList from './NFTList';
import NFTDisplay from './NFTDisplay';
import NFTFilter from './NFTFilter';
import BearsContract from '../../helpers/BearsContract';
import {ethers } from 'ethers';
import config from '../../config';
import HivesContract from '../../helpers/HivesContract';
import BeesContract from '../../helpers/BeesContract';
import VXContract from '../../helpers/VXContract';
import { currentWallet } from '../../helpers/wallets';
import fetchData from '../../helpers/fetchMetadata';
import contractOptions from '../../helpers/contractOptions';
import Footer from '../reusable/footer';
import Loader from './loading';


interface NFTGalleryProps {
    contract: contractOptions;
    showTooltips?: boolean;
    title: string;
}

const NFTGallery: React.FC<NFTGalleryProps> = ({ contract, title, showTooltips = false }) => {
    const [nfts, setNfts] = useState<Array<any>>([]);
    const [filteredNfts, setFilteredNfts] = useState<Array<any>>([]);
    const [selectedNFT, setSelectedNFT] = useState<string>('');
    const provider = new ethers.providers.JsonRpcProvider(config.infuraUrl, 'mainnet');
    const { autoConnect, useAccount, useName, useIsConnected, useChainId } = currentWallet;
    const account = useAccount();
    const [animBG, setAnimBg] = useState(false);
    const isConnected = useIsConnected();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        setTimeout(() => {
            setAnimBg(true);
        }, 300);

    }, [])

    useEffect(() => {
        const fetchNFTs = async () => {
            setIsLoading(true);
            let _baseContract = getContract();

            if (account && _baseContract) {
                let nfts: any[] = [];

                try {
                    const ids = await _baseContract.tokensOfOwner(account);

                    const promises: Promise<any>[] = ids.map(async (id) => {
                        const md = await fetchData(id.toNumber(), contract);
                        const parsedMd = await md.json();
                        const nft = {
                            id: Number(parsedMd.name.split("#")[1]),
                            name: parsedMd.name,
                            image: parsedMd.image,
                            metaverse_url: parsedMd?.metaverse_url,
                            animation_url: parsedMd?.animation_url,
                            metadata: parsedMd.attributes,
                        };
                        return nft;
                    });

                    const metadata = await Promise.all(promises);

                    metadata.forEach((nft) => {
                        nfts.push(nft);
                    });

                    // Set the fetched NFTs
                    setNfts(nfts);
                    setFilteredNfts(nfts);
                    setSelectedNFT(nfts[0].id);
                    setIsLoading(false);
                } catch (error) {
                    console.log(error);
                }
            }
        };

        fetchNFTs();
    }, [contract, account]);



    const getContract = () => {
        if (contract == 'bears') {
            return new BearsContract(provider);
        } else if (contract == 'hives') {
            return new HivesContract(provider);
        } else if (contract == 'bees') {
            return new BeesContract(provider);
        } else if (contract == 'vx') {
            return new VXContract(provider);
        }
    }

    const handleFilter = (searchText: string) => {
        if (!searchText) {
            setFilteredNfts(nfts);
        } else {
            setFilteredNfts(
                nfts.filter(nft =>
                    nft.metadata.some((trait: any) =>
                        String(trait.value).toLowerCase().includes(searchText.toLowerCase())
                    )
                )
            );
        }
    };


    if (!isConnected) {
        return (
            <div style={{ zIndex: 1 }} className={`flex flex-col justify-between h-full bg-gradient-to-t to-transparent via-transparent from-mainbg transition-all duration-1000 bg-size-300 ${animBG ? "bg-pos-100" : "bg-pos-0"}`}>
            <div className="flex flex-col items-center justify-center min-h-screen">
                <h2 className="text-2xl font-semibold text-white">
                    Connect your wallet to view assets.
                </h2>
            </div>
        </div>
        )
    }

    if (isLoading && isConnected) {
        return (
            <Loader />
        );
    };
    
    if (nfts.length === 0) {
        return (
            <div style={{ zIndex: 1 }} className={`flex flex-col justify-between bg-gradient-to-t to-transparent via-transparent from-mainbg transition-all duration-1000 bg-size-300 ${animBG ? "bg-pos-100" : "bg-pos-0"}`}>

                <div className="flex flex-col items-center justify-center min-h-screen">
                    <h2 className="text-2xl font-semibold text-white">
                        You don't own any of these assets yet.
                    </h2>
                </div>
            </div>
        );
    }

    return (
        <div style={{ zIndex: 1 }} className={`flex flex-col justify-between bg-gradient-to-t to-transparent via-transparent from-mainbg transition-all duration-1000 bg-size-300 ${animBG ? "bg-pos-100" : "bg-pos-0"}`}>
            <h1 className="text-5xl text-center center font-bold text-white mt-8">{title}</h1>
            <div className="w-full h-1 my-4"></div>
            <div className="flex flex-col md:flex-row w-full md:items-start">
                <div className="w-full md:w-3/4 order-1 md:order-none">
                    {selectedNFT && <NFTDisplay showTooltips={showTooltips ? true: false} contract={contract} nft={nfts.find(nft => nft.id === selectedNFT)} />}
                </div>
                <div className="w-full md:w-2/5 order-none md:order-1 flex flex-col items-center justify-center">
                    <NFTFilter onFilter={handleFilter} count={filteredNfts.length} />
                    <NFTList nfts={filteredNfts} selectedNFT={selectedNFT} onSelectNFT={setSelectedNFT} contract={contract}  />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default NFTGallery;

