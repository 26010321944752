import React from 'react';
import { useTransactionContext } from '../helpers/TransactionContext';

interface ITXSpinnerProps {
    handleSpinnerClick: () => void
}

const TransactionSpinner: React.FC<ITXSpinnerProps> = (props: ITXSpinnerProps) => {
  const { pendingTransactions } = useTransactionContext();

  return (
    <div onClick={() => {props.handleSpinnerClick()}} className="transaction-spinner">
      <span className={`${pendingTransactions.length == 0 ? 'hidden' : ''}`}>{pendingTransactions.length}</span>
      {/* Add your spinner animation component or HTML/CSS here */}
    </div>
  );
};

export default TransactionSpinner;
