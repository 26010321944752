import React, { CSSProperties, useState } from 'react';
import "../../App.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface TooltipProps {
  message: string;
  style: CSSProperties;
  position?: 'top' | 'bottom';
}

const Tooltip: React.FC<TooltipProps> = ({ message, style, position = 'top' }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const tooltipStyle = position === 'top' ? 'bottom-0' : 'top-0';

  return (
    <div style={style} className="relative inline-flex" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className="tooltip-info-icon">
        <FontAwesomeIcon icon={["far", "question-circle"]} className="text-white cursor-pointer" />
      </div>
      {showTooltip && (
        <div className={`tooltip-message absolute ${tooltipStyle} left-1/2 transform -translate-x-1/2 bg-black text-white text-lg rounded-md p-1 px-3 border border-white min-w-[150px]`}>
          {message}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
