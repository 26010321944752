import config from "../config"
import contractOptions from "./contractOptions";

const fetchData = async (id: number, contract: contractOptions): Promise<any> => {
    let url = ""
    if (contract == 'bears') {
        url = config.bearsCID;
        url += "/" + id;
    } else if (contract == 'hives') {
        url = config.hivesCID;
        url += "/" + id;
    } else if (contract == 'vx') {
        url = config.VXCID;
        url += "/" + id;
    } else if (contract == 'bees') {
        url = config.beesCID;
        url += id;
    };

    return fetch(url);

}

export default fetchData;