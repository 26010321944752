import React from 'react';
import config from '../../config';

const Footer = () => {
    return (
        <footer className="text-white py-4 h-full">
            <div className='flex justify-center'>
                <a href={`${config.siteUrl}contact`} className="text-center mr-2 underline">Contact</a>
                <a href={`${config.siteUrl}terms`} className="text-center underline">Terms</a>
            </div>
            <div className="container mx-auto px-6">
                <p className="text-center">Copyright &copy; {new Date().getFullYear()} Bears Deluxe</p>
            </div>
        </footer>
    );
};

export default Footer;