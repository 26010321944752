import React, { useEffect, useState, useCallback } from 'react';
import Nav from './components/navbar';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom'
import { NavOptions, isNavOptions } from './helpers/customTypes';
import About from './components/about';
import WalletModal from './helpers/walletModal';
import { currentWallet, getWalletConfig } from './helpers/wallets';
import { detectProvider } from '@web3-wallet/detect-provider';
import usePrevious from './customHooks/usePrevious';
import Footer from './components/reusable/footer';
import type { Container, Engine } from "tsparticles-engine";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import MilestoneHistory from './components/history';
import leveledBee from "./images/14612.png";
import fullbear from "./images/fullbear.png";
import honeykartsprev from "./images/honeykartsprev.png";
import hungry_bear from "./images/hungry_bear.png";
import flighthungry from "./images/flighthungry.png";
import rainbowhungry from "./images/rainbowhungry.png";
import mint_out from "./images/mint_out.png";
import mystichive from "./images/mystichive.gif";
import nyc from "./images/nyc.png";
import og_log from "./images/og_log.png";
import ordinalbear from "./images/ordinalbear.png";
import stacked1 from "./images/stacked1.png";
import nftpaint from "./images/nftpaint.png";
import vx from "./images/vx.png";
import vxthumb from "./images/vxthumb.png";
import { TransactionProvider } from './helpers/TransactionContext';
import TransactionModal from './components/TransactionModal';
import { CurrentWallet } from '@web3-wallet/react';
import NFTGallery from './components/reusable/NFTGallery';
import OptionsGrid from './components/reusable/OptionsGrid';
import bear2824 from './images/2824.png';
import bee12045 from './images/12045.png';
import desertHive from './images/deserthive.gif';
// import banner from './images/bearsbanner.png';
import banner from './images/bearsbanner.gif';
import NFTLoader from './components/reusable/NFTLoader';
import useWindowSize from './customHooks/useWindowSize';
import TermsOfService from './components/terms';
import Contact from './components/contact';

const assetOptions = [
  {
    title: 'Bears',
    imageUrl: bear2824,
    route: '/assets/bears',
    external: false
  },
  {
    title: 'VX',
    imageUrl: vxthumb,
    route: '/assets/vx',
    external: false
  },
  {
    title: 'Bees',
    imageUrl: bee12045,
    route: '/assets/bees/view',
    external: false
  },
  {
    title: 'Hives',
    imageUrl: desertHive,
    route: '/assets/hives/view',
    external: false
  },
  {
    title: 'Honey Farm',
    imageUrl: banner,
    route: 'https://farm.bearsdeluxe.io',
    external: true
  },
]

const assetOptionsGames = [
  {
    title: 'Play Hungry Bear',
    imageUrl: hungry_bear,
    route: '/games/hungrybear',
    external: false
  },
  {
    title: 'Create with NFT Paint',
    imageUrl: nftpaint,
    route: 'https://paint.bearsdeluxe.io',
    external: true
  },
  {
    title: 'Play Honey Karts Deluxe',
    imageUrl: honeykartsprev,
    route: 'https://kartsportal.bearsdeluxe.io',
    external: true
  }
]

const hungryBearOPtions = [
  {
    title: 'Level 1 - Flight of the Bear',
    imageUrl: flighthungry,
    route: 'https://bearsdeluxe.mypinata.cloud/ipfs/QmbxQcXXL94HrecdszUFnFhtsN9dvBJdNdYG9zVz9Uvg88',
    external: true
  },
  {
    title: 'Level 2 - Deep in the Honey',
    imageUrl: hungry_bear,
    route: 'https://bearsdeluxe.mypinata.cloud/ipfs/Qmd1cz5dGBpfjfkx5aKx6Yox94CNGAuLmr8y895de4zRQL',
    external: true
  },
  {
    title: 'Level 3 - Rainbow Route',
    imageUrl: rainbowhungry,
    route: 'https://bearsdeluxe.mypinata.cloud/ipfs/Qmb49G4GPfELUEikDwKfLpefsyG2joKAddBVBC2KeuJ8VF',
    external: true
  },
]

const assetOptionsBears = [
  {
    title: 'My Bears',
    imageUrl: bear2824,
    route: '/assets/bears/view',
    external: false
  },
  {
    title: 'Migrate Bears',
    imageUrl: banner,
    route: 'https://farm.bearsdeluxe.io/migrate',
    external: true
  },
]


const milestones = [
  {
    year: 'AUGUST 29th 2021',
    event: 'Bears Deluxe Listed on Opensea',
    description: "The origin story of Bears Deluxe is very unique and stands out in many ways. The project creator, PixelMage, hand-drew each trait using Aseprite, a pixel art software. Following this, all 6,900 Bears were generated by a custom built algorithm. In a bold move, PixelMage listed the entire collection, fully revealed, for sale on OpenSea. To achieve this, they employed an automated script to upload the Bears to OpenSea's shared storefront. Finally, to 'lazy-mint' each Bear, artist and developer PixelMage personally signed a transaction on a hardware wallet for each bear, solidifying the uniqueness of this deluxe collection.",
    image: og_log,
  },
  {
    year: 'SEPTEMBER 12th 2021',
    event: 'Hungry Bear Demo',
    description: "To help the project mint out and demonstrate their ability to make games, PixelMage and PixelKnight created the platformer game 'Hungry Bear' in the pico-8 game engine. The game was hosted on ipfs in a single file and served as a proof of concept for putting a full game directly in the NFT metadata. 'Hungry Bear' is a fun retro platform in the spirit of sonic, and is still playable on this website today.",
    image: hungry_bear,
  },
  {
    year: 'SEPTEMBER 29th 2021',
    event: 'Bears Deluxe Sells Out',
    description: "Bears Deluxe experienced phenomenal success and swiftly became a top-performing project in terms of volume and popularity. As its popularity increased, the project's ambitions expanded, necessitating a migration to a custom smart contract in order to implement innovative ideas. Impressively, even years after its launch, Bears Deluxe continues to hold the distinction of being the highest-volume project on OpenSea's shared storefront, a testament to its enduring appeal.",
    image: mint_out,
  },
  {
    year: 'November 17th 2021',
    event: 'Bears Deluxe Contract Migration, HoneyD, Bees Deluxe, and Hives Deluxe',
    description: "Upon migrating tokens to a custom smart contract, a whole new ecosystem was unlocked for Bears Deluxe. Token migration allowed bear holders to claim complimentary 'Hives Deluxe' and utilize them to mint 'Bees Deluxe' using the HoneyD tokens they had accumulated. The ecosystem was fueled by an upgradable proxy smart contract system featuring role-based permissions, which ensured its flexibility and adaptability. This inventive smart contract ecosystem was designed to evolve and flourish over time. Bears Deluxe holds the distinction of being one of the pioneering NFT projects to implement such a sophisticated system for ecosystem management, further solidifying its position as an innovative force within the NFT space.",
    image: mystichive,
  },
  {
    year: 'February 24th 2022',
    event: 'Honeycomb and Bee Leveling',
    description: "The introduction of Honeycombs and Bee Leveling added an engaging dimension to the Bears Deluxe ecosystem. Collectors could now extract ERC-1155 Honeycomb tokens from their Hives and use them to level up their Bees. This enjoyable and entirely on-chain experience capitalized on the upgradable smart contract system, showcasing the project's commitment to innovation and continuous enhancement of the user experience.",
    image: leveledBee,
  },
  {
    year: 'June 17th 2022',
    event: 'NYC Event',
    description: "The project team hosted a distinctive event at the renowned Flat Iron Room in New York City, providing an opportunity for collectors who had become friends online to finally meet in person. Attendees enjoyed not only each other's company but also complimentary custom cocktails, crafted exclusively for the occasion. To further mark the memorable gathering, all participants were gifted a limited edition Bears Deluxe skate deck, symbolizing their shared enthusiasm for the unique project.",
    image: nyc,
  },
  {
    year: 'August 24th 2022',
    event: 'VX Deluxe released',
    description: "The release of VX Deluxe brought an exciting new dimension to the Bears Deluxe universe. For each Bears Deluxe, a corresponding 3D Voxel version became claimable using HoneyD tokens. An extra 3,100 VX Deluxe were also created, featuring 17 coveted Legendary VX. While these assets were initially designed for integration into the Sandbox Game, their versatility allowed them to be showcased in original projects by the Bears Deluxe team and even licensed for use in animated shorts. This expansion further demonstrated the creative potential and enduring appeal of the Bears Deluxe ecosystem.",
    image: vx,
  },
  {
    year: 'September 29th 2022',
    event: 'Honey Karts Deluxe Alpha',
    description: "To commemorate the one-year anniversary of the project, a fun and straightforward 3D online multiplayer racing game, Honey Karts, was released in beta. As of November 2022, any VX Deluxe can be used within the game. Although still under development, Honey Karts has seen several updates, with the community actively engaging in its growth. VX Deluxe owners can download and play the game today, competing on the test track and even vying for a spot on the leaderboard. This interactive addition highlights the project's ongoing commitment to enhancing the user experience and fostering community involvement.",
    image: honeykartsprev,
  },
  {
    year: 'January 5th 2023',
    event: 'Animated Pixel Bears',
    description: "Every Bears Deluxe is given a matching animated pixel bear. These bears can be implemented in any pixel based game, and will be featured in the upcoming 'Quest for Nectar'.",
    image: fullbear,
  },
  {
    year: 'January 29th 2023',
    event: 'Branding Materials and Deluxe Aesthetics',
    description: "In an effort to further expand Bears Deluxe, the project team developed official branding materials, making them accessible to the community. As a celebration of this milestone, custom clothing was introduced, which collectors could claim using their HoneyD tokens. This move not only added value for Bears Deluxe enthusiasts but also served to strengthen the project's brand identity and foster a sense of unity among its collectors.",
    image: stacked1,
  },
  {
    year: 'February 16th 2023',
    event: 'Ordinal Inscription',
    description: "DNA created for the bears, inspired by projects like CryptoPunks, in order to inscribe all 6,900 Bears Deluxe into Bitcoin Ordinal 104201. The metadata of the Bears Deluxe NFTs were updated to point to their unique ordinal URL, so collectors can enjoy their bears on both blockchains.",
    image: ordinalbear,
  },
  {
    year: 'September 20th 2023',
    event: 'NFT Paint',
    description: "NFT Paint released. An application where users can create pixel art and mint it directly to the blockchain in a variety of colors and sizes. Unique and innovative features include 'Remixing' existing paintings, or loading them directly into the painting app from the contract for alteration. Every painting is verified cryptographically with a hash and guaranteed to be unique, no one's work can be minted twice. NFT Paint serves as both a love letter to Mario Paint and also as a unique art expirement made possible by the unique properties of the ethereum network.",
    image: nftpaint,
  },
]

function App() {

  const [activeUrl, setActiveUrl] = useState<NavOptions>('/');
  const [open, setOpen] = useState(false);
  const { useAccount, useIsConnected, useChainId } = currentWallet;
  const account = useAccount();
  const prevAccount = usePrevious(account);
  const chainId = useChainId();
  const isConnected = useIsConnected();
  const size = useWindowSize()
  const [isTxModalOpen, setIsTxModalOpen] = useState(false);

  useEffect(() => {

    if (isConnected === false) {
      checkConnection();
    };
  }, []);

  useEffect(() => {

    console.log("changed account");
    console.log(account);

    // if account changed from an account to undefined, it means user manually disconnected account
    // check for storage on wallet status and update to disconnected if it exists
    // if it doesn't exists don't do anything because we want to be in disconnected state
    if (prevAccount !== account && prevAccount !== undefined) {
      let statusString = localStorage.getItem("walletStatus");
      if (statusString !== null) {
        let status = JSON.parse(statusString);
        localStorage.setItem("walletStatus", JSON.stringify({
          walletName: status["walletName"],
          chainId: chainId,
          status: 'disconnected'
        }));
      };

    };

  }, [account])

  useEffect(() => {

    //if chain is changed manually by user detect chain andupdate storage token

    console.log("chain changed");
    console.log(chainId);

    let statusString = localStorage.getItem("walletStatus");
    if (statusString !== null) {
      let status = JSON.parse(statusString);
      if (status["chainId"] !== chainId && chainId !== undefined && chainId !== null) {
        localStorage.setItem("walletStatus", JSON.stringify({
          walletName: status["walletName"],
          chainId: chainId,
          status: 'connected'
        }));
      };

    }

  }, [chainId])

  const particlesInit = useCallback(async (engine: Engine) => {
      console.log(engine);

      // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container: Container | undefined) => {
      await console.log(container);
  }, []);


  const checkConnection = () => {
    let statusString = localStorage.getItem("walletStatus");

    if (statusString !== null) {
      let status = JSON.parse(statusString);
      if (status["status"] == "connected") {
        currentWallet.connectAsCurrentWallet(status["walletName"], status["chainId"])
          .then(res => {
            localStorage.setItem("walletStatus", JSON.stringify({
              walletName: status["walletName"],
              chainId: status["chainId"],
              status: 'connected'
            }));
          })
          .catch(res => {
            localStorage.setItem("walletStatus", JSON.stringify({
              walletName: status["walletName"],
              chainId: status["chainId"],
              status: 'disconected'
            }));
            currentWallet.disconnect();
          });

      } else if (status["status"] == "disconnected") {
        // disconnected logic here, empty for now

      };
    } else {
      // logic for no storage item yet, empty for now

    }
  }

  const handleSpinnerClick = () => {
    setIsTxModalOpen((prev) => !prev);
  };


  return (
    <TransactionProvider>
      <div className='bg-gray-800 h-full'>
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            style={{mixBlendMode: 'screen'}}
            options={{
                fpsLimit: 60,
                interactivity: {
                    events: {
                        onClick: {
                            enable: true,
                            mode: "push",
                          },
                          onHover: {
                            enable: true,
                            mode: "repulse",
                            parallax: { enable: false, force: 3, smooth: 10 }
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 4,
                        },
                        repulse: {
                            distance: 100,
                            duration: 0.7,
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#83f6fc",
                    },
                    collisions: {
                        enable: false,
                    },
                    move: {
                        direction: "top",
                        enable: true,
                        outModes: "out",
                        random: false,
                        speed: 3,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 80,
                    },
                    opacity: {
                      value: {
                        min: 0,
                        max: 0.5
                      }
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 1, max: 5 },
                    },
                },
                detectRetina: true,
            }}
        />
        <div style={{zIndex:1, position: "relative"}} className={size[0] > 1024 ? "" : "h-full"}>
          <Nav 
            open={open}
            setOpen={setOpen}
            setActiveUrl={setActiveUrl}
            activeUrl={activeUrl}
            handleTxModalClick={handleSpinnerClick}
          />
          <Routes>
            <Route path="/" element={<About />}/>
            <Route path="/terms" element={<TermsOfService />}/>
            <Route path="/contact" element={<Contact />}/>
            <Route path="/history" element={<MilestoneHistory milestones={milestones}/>}/>
            <Route path='/assets' element={<OptionsGrid title="Assets" options={assetOptions}/>}/>
            <Route path='/games' element={<OptionsGrid title="Games" options={assetOptionsGames}/>}/>
            <Route path='/games/hungrybear' element={<OptionsGrid title="Hungry Bear" options={hungryBearOPtions}/>}/>
            <Route path='/assets/bears' element={<OptionsGrid title="Bears Deluxe" options={assetOptionsBears}/>}/>
            <Route path="/assets/bears/view" element={<NFTGallery title={'My Bears Deluxe'} showTooltips={true} contract={'bears'}/>} />
            <Route path="/assets/bees/view" element={<NFTGallery title={'My Bees Deluxe'} contract={'bees'}/>} />
            <Route path="/assets/hives/view" element={<NFTGallery title={'My Hives Deluxe'} contract={'hives'}/>} />
            <Route path="/assets/vx" element={<NFTGallery title={'My VX Deluxe'} contract={'vx'}/>} />
            <Route path="/bears/:id" element={<NFTLoader contract='bears' />} />
            <Route path="/bees/:id" element={<NFTLoader contract='bees' />} />
            <Route path="/hives/:id" element={<NFTLoader contract='hives' />} />
            <Route path="/vx/:id" element={<NFTLoader contract='vx' />} />
          </Routes>
          <WalletModal 
            open={open}
            setOpen={setOpen}
          />
          {isTxModalOpen && (
            <div onClick={handleSpinnerClick}>
              <TransactionModal open={isTxModalOpen} setOpen={setIsTxModalOpen} />
            </div>
          )}
        </div>
      </div>
    </TransactionProvider>
  );
}
export default App;
