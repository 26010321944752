import React, { FC, useEffect, useRef } from 'react';

interface SpriteSheetProps {
  imageUrl: string;
  frameRate?: number;
  scale?: number;
  startingFrame: number;
  frameCount: number;
  columns: number;
}

const SpriteSheet: FC<SpriteSheetProps> = ({
  imageUrl,
  frameRate = 1000 / 4,
  scale = 1,
  startingFrame = 0,
  frameCount = 4,
  columns = 8,
}) => {
  const frameSize = 32;
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  let currentFrame = startingFrame;
  const image = new Image();
  image.src = imageUrl;

  const drawImage = () => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      if (ctx) {
        ctx.imageSmoothingEnabled = false; // Keep it crispy! 🍟
  
        const frameIndex = currentFrame - startingFrame;
        const column = (startingFrame + frameIndex) % columns;
        const row = Math.floor((startingFrame + frameIndex) / columns);
        ctx.clearRect(0, 0, frameSize * scale, frameSize * scale);
  
        ctx.drawImage(
          image,
          column * frameSize,
          row * frameSize,
          frameSize,
          frameSize,
          0,
          0,
          frameSize * scale,
          frameSize * scale
        );
      }
    }
  };
  
  

  useEffect(() => {
    image.onload = drawImage;
  }, []);

  useEffect(() => {
    currentFrame = startingFrame;
  
    image.onload = () => {
      drawImage();
    };
  
    if (frameCount > 1) {
      const interval = setInterval(() => {
        currentFrame++;
        if (currentFrame >= startingFrame + frameCount) {
          currentFrame = startingFrame;
        }
        drawImage();
      }, frameRate);
  
      return () => clearInterval(interval);
    } else {
      drawImage();
    }
  }, [frameRate, startingFrame, frameCount, imageUrl]);
  
  

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <canvas
        ref={canvasRef}
        width={frameSize * scale}
        height={frameSize * scale}
        style={{ imageRendering: 'pixelated' }}
      />
    </div>
  );
};

export default SpriteSheet;
