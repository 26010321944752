import React, { useState } from 'react';

interface NFTFilterProps {
  onFilter: (searchText: string) => void;
  count: number;
}

const NFTFilter: React.FC<NFTFilterProps> = ({ onFilter, count }) => {
  const [searchText, setSearchText] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    onFilter(event.target.value);
  };

  return (
    <div className="py-4 flex justify-center items-center space-x-4">
      <div className="flex items-center justify-center w-10 h-10 bg-black bg-opacity-60 border-2 border-white rounded">
        <span className="text-white">{count}</span>
      </div>
      <input
        type="text"
        value={searchText}
        onChange={handleChange}
        className="px-3 py-2 border border-white rounded text-white bg-black bg-opacity-60 focus:outline-none focus:border-blue-500"
        placeholder="Filter by attribute..."
      />
    </div>
  )
};

export default NFTFilter;
