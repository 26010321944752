// components/Loader.tsx
import React, { useEffect, useState } from 'react';
import Footer from '../reusable/footer';

const Loader: React.FC = () => {
  const [dots, setDots] = useState<string>('.');
  const [animBG, setAnimBg] = useState(false);

  useEffect(() => {
      setTimeout(() => {
          setAnimBg(true);
      }, 300);

  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length === 3 ? '.' : prevDots + '.'));
    }, 500);
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ zIndex: 1 }} className={`flex flex-col justify-between h-max bg-gradient-to-t to-transparent via-transparent from-mainbg transition-all duration-1000 bg-size-300 ${animBG ? "bg-pos-100" : "bg-pos-0"}`}>
        <div className="flex items-center justify-center min-h-screen">
        <div className="p-8 rounded-md bg-black bg-opacity-75">
            <h2 className="text-white text-2xl font-semibold" style={{ width: '6rem' }}>
            Loading<span className="absolute">{dots}</span>
            </h2>
        </div>
        </div>
        <Footer />
    </div>
  );
};

export default Loader;
