import { Contract, ContractInterface, ethers, Signer } from 'ethers';

export default abstract class BaseContract {
    contract: Contract;

    constructor(
        contractAddress: string,
        abi: ContractInterface,
        signer: Signer | ethers.providers.Provider
    ) {
        this.contract = new ethers.Contract(contractAddress, abi, signer);
    }

    protected async callFunction(
        functionName: string,
        args: any[] = [],
        options: ethers.PayableOverrides = {},
        extraGas: number = 5000
    ): Promise<any> {
        const contractFunction = this.contract[functionName];
        const stateMutability = this.contract.interface.getFunction(functionName).stateMutability;

        if (typeof contractFunction === 'undefined') {
            throw new Error(`Function ${functionName} not found in the contract ABI.`);
        }

        if (stateMutability == 'view' || stateMutability == 'pure') {
            return await contractFunction(...args, options);
        } else {
            const gasEstimate = await contractFunction.estimateGas(...args, options);
            options.gasLimit = gasEstimate.add(extraGas);
            return await contractFunction(...args, options);
        };
    }
}
