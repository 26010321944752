import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useThree, useFrame } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from '@react-three/drei';
import ReactDOM from 'react-dom';
import * as THREE from 'three';
import Tooltip from './reusable/Tooltip';

interface ModelDisplayProps {
  modelUrl: string;
}

const Model: React.FC<{ setIsLoaded: (loaded: boolean) => void, modelUrl: string, cameraRef: React.RefObject<THREE.PerspectiveCamera>, onAnimationsLoaded: (animations: THREE.AnimationClip[]) => void, selectedAnimation: number | null }> = ({ setIsLoaded, modelUrl, cameraRef, onAnimationsLoaded, selectedAnimation }) => {
  const [model, setModel] = useState<THREE.Object3D | null>(null);
  const mixerRef = useRef<THREE.AnimationMixer>();
  const [animations, setAnimations] = useState<THREE.AnimationClip[] | null>(null);

  useEffect(() => {
    if (model && animations) {
      mixerRef.current = new THREE.AnimationMixer(model);
      const clips = animations;
      if (clips && clips.length > 0) {
        const firstClip = clips[0];
        const action = mixerRef.current.clipAction(firstClip);
        action.setLoop(THREE.LoopRepeat, Infinity);
        action.play();
        model.rotation.y = THREE.MathUtils.degToRad(70);
        model.scale.y = -1;

      }
    }
  }, [model, animations]);

  // Update the animation mixer on each frame
  useFrame((_, delta) => {
    mixerRef.current?.update(delta);
  });

  useEffect(() => {
    if (model && animations) {
      mixerRef.current = new THREE.AnimationMixer(model);
      const clips = animations;
      if (clips && clips.length > 0) {
        const selectedClip = clips[selectedAnimation !== null ? selectedAnimation : 0];
        const action = mixerRef.current.clipAction(selectedClip);
        action.setLoop(THREE.LoopRepeat, Infinity);
        action.play();
        model.rotation.y = THREE.MathUtils.degToRad(70);
        model.scale.y = -1;
      }
    }
  }, [selectedAnimation])

  useEffect(() => {
    const loader = new GLTFLoader();
    loader.load(
      modelUrl,
      (gltf) => {
        const scene = gltf.scene;

        // Set the rotation and scale of the model to make it look better
        scene.castShadow = false;

        // Traverse through the children of the model and set the emissive color and flat shading
        scene.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            const material = child.material as THREE.MeshStandardMaterial;

            if (material.map) {
              material.map.generateMipmaps = false;
            }
          }
        });

        setModel(scene);
        setAnimations(gltf.animations);
        onAnimationsLoaded(gltf.animations);
        setIsLoaded(true);
      },
      undefined,
      (error) => {
        console.error('An error occurred loading the GLTF model:', error);
      }
    );
  }, [modelUrl]);

  return model ? <primitive object={model} /> : null;
};

const ModelDisplay: React.FC<ModelDisplayProps> = ({ modelUrl }) => {
  const width = 800;
  const height = 600;
  const cameraRef = useRef<THREE.PerspectiveCamera>(null);
  const [isLoaded, setIsloaded] = useState(false);
  const [animations, setAnimations] = useState<THREE.AnimationClip[] | null>(null);
  const [selectedAnimation, setSelectedAnimation] = useState<number | null>(null);

  // Wait for the model to load before showing it
  useEffect(() => {
    if (isLoaded) {
      cameraRef.current?.lookAt(new THREE.Vector3(0, 0, 0));
    }
  }, [isLoaded]);

  useEffect(() => {
    setIsloaded(false);
  }, [modelUrl])

  const handleAnimationsLoaded = (animations: THREE.AnimationClip[]) => {
    setAnimations(animations);
  };

  const handleAnimationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedIndex = event.target.selectedIndex;
    setSelectedAnimation(selectedIndex);
  };

  return (
    <div style={{ position: 'relative', width: `${width}px`, height: `${height}px` }}>
      {isLoaded == true ? null : (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
            backgroundColor: 'white',
            padding: '10px',
            borderRadius: '5px',
            background: 'black',
            opacity: .8,
            border: "solid 1px white"
          }}
          className="text-white text-3xl p-4 rounded"
        >
          Loading...
        </div>
      )}
      {isLoaded == false ? null : (
      <div
        style={{
          position: 'absolute',
          top: '1px',
          right: '12%',
          zIndex: 1,
        }}
        // className='flex flex-row justify-center'
      >
        
          <Tooltip style={{ marginRight: "5px", position: 'relative', top: '5px', fontSize: "1.3em" }} message="Choose animation in the dropdown. Click and drag to rotate. Shift+click and drag to move entire model." />
          <select
            className="bg-black bg-opacity-50 text-white border border-white rounded"
            onChange={handleAnimationChange}
          >
            {animations?.map((animation, index) => (
              <option key={index} value={index}>
                {animation.name.split("0")[0]}
              </option>
            ))}
          </select>
      </div>
      )}
      <Canvas
        style={{ width: `${width}px`, height: `${height}px`, backgroundColor: 'transparent' }}
        camera={{ position: [60, 60, 10], fov: 65, aspect: width / height, near: 0.1, far: 1000 }}
        gl={{ antialias: false, powerPreference: 'high-performance' }} // Disable antialiasing and enable high-performance mode
      >
        <ambientLight intensity={0.5} />
        <directionalLight position={[9, 28, -15]} intensity={1.5} />
        <OrbitControls target={[0, 40, 0]} />
        <Model setIsLoaded={setIsloaded} modelUrl={modelUrl} cameraRef={cameraRef} selectedAnimation={selectedAnimation} onAnimationsLoaded={handleAnimationsLoaded} />
        <mesh position={[0, 0, -2]} rotation={[-Math.PI / 2, 0, 0]}>
          <circleBufferGeometry args={[40, 32]} />
          <meshStandardMaterial emissive={'aqua'} emissiveIntensity={.7} transparent opacity={0.2} color={'aqua'} />
        </mesh>
        <perspectiveCamera ref={cameraRef} />
      </Canvas>
    </div>
  );
};

export default ModelDisplay;
