import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NFTDisplay from './NFTDisplay';
import fetchData from '../../helpers/fetchMetadata';
import contractOptions from '../../helpers/contractOptions';
import Footer from './footer';


interface NFTLoader {
    contract: contractOptions;
}

const NFTLoader: React.FC<NFTLoader> = ({ contract }) => {

    const [nft, setNft] = useState<any>();
    const { id } = useParams<{ id: string }>();
    const [animBG, setAnimBg] = useState(false);


    useEffect(() => {
        setTimeout(() => {
            setAnimBg(true);
        }, 300);

    }, [])

    useEffect(() => {
        fetchData(Number(id), contract).then((md) => {
            md.json().then((parsedMd: any) => {
                const _nft = {
                    id: Number(parsedMd.name.split("#")[1]),
                    name: parsedMd.name,
                    image: parsedMd.image,
                    metaverse_url: parsedMd?.metaverse_url,
                    animation_url: parsedMd?.animation_url,
                    metadata: parsedMd.attributes,
                };
                setNft(_nft);
            })
        })
    }, []);

    return (
        <div style={{ zIndex: 1 }} className={`min-h-screen flex flex-col justify-between h-full bg-gradient-to-t to-transparent via-transparent from-mainbg transition-all duration-1000 bg-size-300 ${animBG ? "bg-pos-100" : "bg-pos-0"}`}>

            <>
                {
                    nft &&
                    <>
                        <h1 className="text-4xl text-center center font-bold text-white mt-8">{nft.name}</h1>
                        <div className="w-full h-1 my-4"></div>
                        <NFTDisplay contract={contract} nft={nft} disable={true} />
                    </>
                }
                {
                    nft &&
                    <Footer />
                }
            </>
        </div>
    )
}

export default NFTLoader;