import React from 'react';
import { Link } from 'react-router-dom';

interface OptionCardProps {
  title: string;
  imageUrl: string;
  route: string;
  external: boolean;
}

const OptionCard: React.FC<OptionCardProps> = ({ title, imageUrl, route, external }) => {
  const handleClick = () => {
    if (external) {
      window.open(route, '_blank');
    }
  };

  return (
    <div
      className="w-72 h-72 p-4 m-4 bg-black bg-opacity-50 rounded-lg shadow-md flex flex-col items-center justify-center text-center cursor-pointer transition-all duration-200 hover:bg-opacity-75 transform hover:scale-105 border-2 border-light-blue-500"
      onClick={handleClick}
    >
      {external ? (
        <img
          src={imageUrl}
          alt={title}
          className="w-48 h-48 mb-4 object-cover object-center rounded backface-visibility:hidden"
        />
      ) : (
        <Link to={route}>
          <img
            src={imageUrl}
            alt={title}
            className="w-48 h-48 mb-4 object-cover object-center rounded backface-visibility:hidden"
          />
        </Link>
      )}
      <h3 className="text-2xl font-semibold text-white">{title}</h3>
    </div>
  );
};

export default OptionCard;
