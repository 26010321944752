// OptionsGrid.tsx
import React, { useEffect, useState } from 'react';
import OptionCard from './OptionCard';
import Footer from '../reusable/footer';


interface Option {
  title: string;
  imageUrl: string;
  route: string;
  external: boolean;
}

interface OptionsGridProps {
  options: Option[];
  title: string;
}

const OptionsGrid: React.FC<OptionsGridProps> = ({ options, title }) => {

    const [animBG, setAnimBg] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setAnimBg(true);
        }, 300);

    }, [])
    
  return (
    <div style={{ zIndex: 1 }} className={`flex flex-col min-h-screen justify-between bg-gradient-to-t to-transparent via-transparent from-mainbg transition-all duration-1000 bg-size-300 ${animBG ? "bg-pos-100" : "bg-pos-0"}`}>
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 w-auto flex-grow">
        <h2 className="text-3xl font-semibold text-center text-white mb-10">
            {title}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 place-items-center">
            {options.map((option, index) => (
            <OptionCard
                key={index}
                title={option.title}
                imageUrl={option.imageUrl}
                route={option.route}
                external={option.external}
            />
            ))}
        </div>
        </div>
        <Footer />
    </div>
  );
};

export default OptionsGrid;
