import React from 'react';
import { useTransactionContext } from '../helpers/TransactionContext';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { currentWallet, getWalletConfig, walletConfigs } from '../helpers/wallets';


interface ITransactionModalProps {
    setOpen: (open: boolean) => void,
    open: boolean
}

const TransactionModal: React.FC<ITransactionModalProps> = (props: ITransactionModalProps) => {
    const { pendingTransactions } = useTransactionContext();
    const cancelButtonRef = useRef(null)
    const { useName, useChainId, useIsConnected, useAccount } = currentWallet;


    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >

                            <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div style={{ border: "1.25px solid #d733d2" }} className='rounded-lg' >
                                    <div style={{ border: "1.25px solid #ffa2f8" }} className='rounded-lg' >
                                        <div style={{ border: "1.25px solid #c4f6f9" }} className='rounded-lg' >
                                            <div style={{ border: "1.25px solid #55bac3" }} className='rounded-lg' >
                                                <div className="bg-gray-800 rounded-t-lg px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex flex-row sm:flex-column space-x-8">
                                                    <h2>Pending Transactions</h2>
                                                    <ul>
                                                        {pendingTransactions.map((transaction) => (
                                                            <li key={transaction.hash}>
                                                                {transaction.hash} - {new Date(transaction.timestamp).toLocaleString()}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )

};

export default TransactionModal;

