import React, { useEffect, useState } from 'react';
import Footer from './reusable/footer';

interface Milestone {
    year: string;
    event: string;
    image: string;
    description: string;
}

interface MilestoneHistoryProps {
    milestones: Milestone[];
}

const MilestoneHistory: React.FC<MilestoneHistoryProps> = ({ milestones }) => {

    const [animBG, setAnimBg] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setAnimBg(true);
        }, 300);

    }, [])

    return (
        <div style={{ zIndex: 1 }} className={`flex flex-col justify-between h-full bg-gradient-to-t to-transparent via-transparent from-mainbg transition-all duration-1000 bg-size-300 ${animBG ? "bg-pos-100" : "bg-pos-0"}`}>
            <div className="flex flex-col items-center justify-center">
                <p style={{ color: "#ffffff" }} className="mt-2 text-5xl mb-8 flex justify-center font-bold tracking-tight sm:text-5xl">
                    History
                </p>
                <div className="grid grid-cols-1">
                    {milestones.map((milestone, index) => (
                        <div key={milestone.year} className={`flex max-w-4xl mr-3 ml-3 flex-col mb-3 items-center justify-center ${index % 2 === 0 ? 'md:flex-row-reverse' : 'md:flex-row'}`}>
                            <img src={milestone.image} alt={milestone.event} style={{ border: index !== 9 ? "1.25px solid #c4f6f9" : "" }}  className="w-80 h-auto scale-90 w-full rounded object-cover mb-4 ml-2 mr-2" />
                            <div className="bg-black bg-opacity-50 mb-4 shadow-md rounded-lg p-4 ml-4 sm:ml-0 text-white font-bold">
                                <p className="text-2xl mb-2">{milestone.year}</p>
                                <p style={{ color: "#ffa2f8" }} className="font-bold text-3xl">{milestone.event}</p>
                                <p className="text-xl">{milestone.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default MilestoneHistory;
