import React from 'react';
import '../../App.css';
import contractOptions from '../../helpers/contractOptions';

interface NFTListProps {
  nfts: Array<any>;
  selectedNFT: string;
  onSelectNFT: (id: string) => void;
  contract: contractOptions;
}

const NFTList: React.FC<NFTListProps> = ({ nfts, selectedNFT, onSelectNFT, contract }) => {
  return (
    <ul className="gutter overflow-x-hidden p-3 flex flex-col w-3/4 md:max-h-820 max-h-56 md:h-auto overflow-y-auto p-2 md:mt-0 scrollbar scrollbar-thin scrollbar-thumb-white scrollbar-track-transparent hide-x-scrollbar">
      {nfts.map((nft) => (
        <div key={nft.id}>
          <li
            onClick={() => onSelectNFT(nft.id)}
            className={`w-full p-2 flex items-center justify-between transition-all duration-200 cursor-pointer bg-black bg-opacity-60 hover:bg-opacity-100 hover:border-blue-200 hover:border-2 rounded transform hover:scale-105 ${
              nft.id === selectedNFT ? 'border-2 border-white rounded' : 'border-2 border-transparent'
            } mb-2 elevate-on-hover`}
          >
            <span className="text-lg text-white">{nft.name}</span>
            <img
              src={contract == 'vx' ? `https://vx-stills.s3.us-east-2.amazonaws.com/new_vx_thumbnails/${nft.id}.png` : nft.image}
              alt={`Thumbnail of NFT ${nft.id}`}
              className="w-16 h-16 object-cover rounded"
            />
          </li>
        </div>
      ))}
    </ul>
  );
};

export default NFTList;
