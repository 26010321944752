import { Fragment, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

interface DropdownProps {
    items: any[],
    callback: (value: any) => void,
    initialSelection?: string,
}


// props: DropdownProps
function Dropdown(props: DropdownProps) {

    const [selectedOption, setSelectedOption] = useState<string>("Please Select ...")

    useEffect(() => {
        if (props.initialSelection) {
            setSelectedOption(props.initialSelection);
        };
     }, []);

    useEffect(() => {
        if (props.initialSelection) {
            setSelectedOption(props.initialSelection);
        }
    }, [props.initialSelection])

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
            <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                <span>
                    {selectedOption}
                </span>
                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </Menu.Button>
            </div>

            <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            >
            <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                {
                    props.items.map((item, i) => {
                        return (
                            <Menu.Item key={i}>
                            {({ active }) => (
                            <a
                                onClick={() => {props.callback(item.value);setSelectedOption(item.text)}}
                                href="#"
                                className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm'
                                )}
                            >
                                {item.text}
                            </a>
                            )}
                        </Menu.Item>)
                    })
                }
                </div>
            </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default Dropdown;