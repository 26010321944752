import React, { useEffect, useState } from 'react';
import Footer from './reusable/footer';

const TermsOfService: React.FC = () => {
  const [animBG, setAnimBg] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAnimBg(true);
    }, 300);

  }, [])

  return (
    <div className={`bg-gradient-to-t to-transparent via-transparent from-mainbg transition-all duration-1000 bg-size-300 ${animBG ? "bg-pos-100" : "bg-pos-0"}`}>
      <div className={`bg-black m-5 bg-opacity-50 text-white p-8 border border-white rounded-md`}>
        <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
        <p>If you’re reading these updated terms, then we are well beyond our initial launch but we are not done with our roadmap. We have many things in the future for those who are involved in our movement and project! Thank you so much for visiting our terms and conditions and if you have any questions feel free to reach out to us in the Discord or on Twitter.</p>
        <p>Any references, terms, or related content to the Bears Deluxe collection on this terms and conditions page entirely encompasses and speaks for all of our other NFT collections including but not limited to Bees Deluxe, and Honey Hives Deluxe</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">2. Definitions</h2>
        <p>"Bears Deluxe" refers to both the original collection of 6,900 randomly generated 2D avatars.</p>
        <p>"VX Bears" when specifically mentioned, refers only to the collection of 10,000 3D avatars built for the metaverse and beyond. </p>
        <p>“Bears Deluxe NFT” is defined as any individual art, design, 2D drawings or 3D models that may be associated with a Bears DeluxeNFT that you Own. </p>
        <p>“Own” is defined as possession of a Bears Deluxe NFT in your Ethereum wallet, where proof of such possession is recorded on the blockchain. </p>
        <p>"Rig/Image/Model" are defined as individual VXR/VXA/VXM/VOX/GLTF/GLB/OBJ Models, PNG/GIF/JPEG and underlying files containing all proprietary Bears Deluxe traits. </p>
        <p>"Traits" are defined as the distinguishing characteristics and proprietary individually layered art files associated with each Bears Deluxe NFT. These includes but is not limited to Eyes, Eyewear, Mouths, Headwear, Body, Clothing, Background and Accessories as well as Wearables and Equipment for VX Bears.</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">3. What We Own</h2>
        <p>The name and Intellectual Property involved in the Bears Deluxe Project (“Bears Deluxe”) is owned by our team. All rights that are not specifically granted to the users and owners of Bears Deluxe below are reserved by the team or potential future DAO. This includes but is not limited to the intellectual property rights surrounding the “Bears Deluxe” and “VX Bears” names, images, logos, 3d layer files, trademarks, the website, the look and feel of the user interface, the smart contract code, or anything else not specifically granted by any following licenses. </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">4. You Own Your Bears Deluxe</h2>
        <p>By holding a Bears Deluxe NFT in your Ethereum wallet, you gain full and complete ownership of your NFT as well as the opportunity to utilize this NFT in minting or receiving subsequent opportunities available only to Bears Deluxe holders. </p>
        <p>All NFTs ownership is verified cryptographically on the Ethereum Blockchain. This is proof of ownership and grants rights within this document. Once the NFT leaves ownership, all Personal and Commercial use terms are revoked.</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">5. Personal Use</h2>
        <p>Subject to compliance with these terms, Bears Deluxe grants you a royalty-free license to use, copy, display, and modify the Bears Deluxe NFTs that you own for Bears Deluxe NFT Art. This usage is granted world-wide. </p>
        <p>This usage is for the limited purposes of:</p>
        <ul className="list-disc pl-5">
          <li className="text-sm">Personal and Non-Commercial Use</li>
          <li className="text-sm mt-2">Display and usage of your NFT on External Websites</li>
          <li className="text-sm mt-2">Ability to Buy, Sell, and Trade your NFT</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">6. Commercial and Derivative Rights</h2>
        <p>We appreciate your desire to utilize Bears Deluxe in various commercial ways, and we are open to you doing just that! When it comes to limiting commercial rights, however, we want you as an NFT holder to realize that you must use the Bears Deluxe in its entirety!</p>
        <p>Use of the Bears Deluxe NFT Licensed Materials must be in full and in their entirety. Licensed use of Bears Deluxe and VX Bears must include the complete Rig/Image/Model and rights are reserved for any and all underlying image files including layered filed for individual proprietary Bears Deluxe traits (ex Rainbow Shades, Top Hats, etc.) or the individual proprietary VOX and VXM files that complete each asset. These rights are reserved for the Team and are expressly prohibited to be used outside of the full Rig/Image/Model. These individual layered files are proprietary and of Bears Deluxe's own creation. </p>
        <p>Bears Deluxe expressly prohibits the use of any proprietary, individual traits or crafted layers in any and all other projects. Bears Deluxe also prohibits the sale or promotion of counterfeit NFTs or NFT collections. Counterfeit NFTs or collections contain art that is identical to or substantially indistinguishable from the original Bears Deluxe collection, including:</p>
        <ul className="list-disc pl-5">
          <li className="text-sm">NFTs described as knock off, replica, imitation, clone, faux, fake, mirror image, or similar terms when referring to an NFT or NFT Collection in an attempt to pass themselves off as genuine Bears Deluxe creations.</li>
          <li className="text-sm mt-2">Non-genuine products that mimic Bears Deluxe features or proprietary traits in an attempt to pass themselves off as genuine Bears Deluxe creations.</li>
        </ul>
        <p>Otherwise, you are free to use your Bears Deluxe in the fullest sense of commercial and derivative use cases.</p>
        <p>However, user shall not use any Bears Deluxe Licensed Materials in any way, or in connection with any material, which is unlawful, fraudulent, libelous, defamatory, obscene, pornographic, profane, threatening, abusive, hateful, offensive or otherwise objectionable or unreasonable or infringing upon any laws or regulations or intellectual property rights or proprietary rights or confidentiality obligations and you shall indemnify and defend Bears Deluxe against any claims, damages, proceedings, loss or costs arising from such use. User shall not use the Bears Deluxe Licensed Materials in any way that could be construed as being adverse or derogatory to the image of Bears Deluxe or any of its subjects.</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">7. Future Projects</h2>
        <p>By holding a Bears Deluxe NFT, you will have the opportunity to participate in a variety of future opportunities, including but not limited to: VX Bears mint reservation, airdrops, access to events in The Sandbox, etc.</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">8. Bears Deluxe are NOT Investment Vehicles</h2>
        <p>Bears Deluxe are collectible digital art pieces that also function as fun, Non-Fungible Tokens for you to collect. They were created as art pieces intended for people to enjoy by collecting, not as a financial instrument. Bears Deluxe makes absolutely no promises or guarantees regarding the value of Bears Deluxe NFTs aside from the one that we will strive to do the best for the project and the community.</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">9. $HONEYD is NOT an Investment Vehicle</h2>
        <p>$HONEYD is a utility token created for use within the Bears Deluxe ecosystem that allows you to partake in fun web3 based experiences. $HONEYD has no financial value, and is not used to generate revenue for the Bears Deluxe team.</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">10. Taxes</h2>
        <p>You are entirely and solely responsible for any tax liability which may arise from minting, selling or owning your Bears Deluxe NFTs, or $HONEYD.</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">11. Children</h2>
        <p>The Bears Deluxe project is not targeted towards children. You agree that you are over the age of 18, or above the legal age of your jurisdiction, whichever is greater.</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">These Terms are Subject to Change</h2>
        <p>We may revise these Terms from time to time, but the most current version will always be on bearsdeluxe.io/terms. You are responsible for reviewing and becoming familiar with any such modifications. Revisions deemed material, in the sole discretion of Bears Deluxe, will include a notification via Twitter or Discord. By continuing to access the Bears Deluxe website and by holding a Bears Deluxe NFT, you agree to be bound by the current Terms.</p>
      </div>

      <Footer />
    </div>
  );
};

export default TermsOfService;