import React, { useEffect }  from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, useLocation }  from 'react-router-dom';
import { Web3WalletQueryClientProvider } from '@web3-wallet/react';
import { library } from '@fortawesome/fontawesome-svg-core';
// import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';

library.add(faCircleQuestion);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

root.render(
  <Router>
    <React.StrictMode>
      <ScrollToTop />
      <Web3WalletQueryClientProvider>
       <App />
      </Web3WalletQueryClientProvider>
    </React.StrictMode>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
